.BarChart {
  text {
    fill: #404040;

    &.invert {
      fill: white;
    }
  }

  font-size: 10px;
 
}
