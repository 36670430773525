@import '../variables.scss';

.PageNavigationMobile {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  z-index: $PageNavigationMobile-z-index;
  height: $PageNavigationMobile-height;

  $padding: 0.5rem;
  padding: $padding;

  box-shadow: 0 -1px 12px rgba(0, 0, 0, 0.08), 0 -1px 4px rgba(0, 0, 0, 0.04);

  &.no-shadow {
    box-shadow: none;
  }

  @media (min-width: $narrow-breakpoint) {
    display: none;
  }

  .button-container {
    width: 30px;
    display: flex;

    &.right {
      justify-content: flex-end;
    }

    .button.invisible {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: -$padding;
      padding: $padding;

      &:hover {
        background-color: $light-background-color;
        outline: none;
      }

      .label {
        svg {
          display: inline;
          stroke: #0048bb;
        }
      }
    }
  }

  .bubbles {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;

    .page {
      cursor: pointer;
      padding: 0.4rem;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;

      .bubble {
        width: 8px;
        height: 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #c0c0c0;
        color: white;
        border-radius: 1000px;
      }

      &.warning .bubble {
        background-color: lighten(#ce4014, 25%);
      }

      &.active {
        cursor: auto;
        color: #0048bb;

        .bubble {
          background-color: #0048bb;
        }

        &.warning .bubble {
          background-color: darken(#ce4014, 10%);
        }
      }
    }
  }
}
