@import '../variables.scss';

.UserList {
  width: 100%;

  .user {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    padding: 0.6rem 0;
    display: flex;

    > {
      padding-right: 0.3rem;
    }

    &.header {
      font-weight: 500;
      color: $lighter-text-color;

      border-bottom: 3px solid #f5f5f5;
      padding-bottom: 0.2rem;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;
    }

    &.user:hover {
      background-color: $hover-background-color;
      padding-right: 0.4rem;
      margin-right: -0.4rem;
      padding-left: 0.4rem;
      margin-left: -0.4rem;

      .realName {
        text-decoration: underline;
        color: $link-color;
      }
    }

    .name {
      width: 60%;
      flex-grow: 1;

      .line {
        display: flex;

        .realName {
          flex-grow: 1;
        }

        .unverified,
        .premium {
          font-weight: 500;
          font-size: 13px;
          background-color: #fbb482;
          color: white;
          padding: 2px 3px;
          margin-left: 0.6em;
        }
      }

      .email {
        margin-top: 0.3rem;
        font-size: 13px;
      }
    }

    .phone,
    .createdAt,
    .type {
      margin-left: 0.5rem;
    }

    .type {
      width: 4rem;
    }

    .phone {
      width: 8em;
      text-align: right;
      display: none;

      @media (min-width: $narrow-breakpoint) {
        display: block;
      }
    }

    .createdAt {
      width: 4rem;
      text-align: right;

      display: none;

      @media (min-width: $narrow-breakpoint) {
        display: block;
      }
    }
  }
}
