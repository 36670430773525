@import '../../variables.scss';

.field:hover {
  .FormFieldSelect,
  .FormFieldMultipleSelect {
    .Caret {
      stroke: $input-line-color;
    }
  }
}

.FormFieldSelect,
.FormFieldMultipleSelect {
  position: relative;
  flex-grow: 0;

  .Caret {
    height: 0.8em;
    width: 0.8em;
    position: absolute;
    right: 0.1rem;
    top: 0.6em;

    stroke-opacity: 1;
    stroke: lighten($input-line-color, 10%);
    stroke-width: 35;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    fill: none;

    z-index: -1;
  }

  :focus + .Caret {
    stroke: $link-color !important;
  }

  :focus + .Caret {
    stroke: $link-color !important;
  }
}

.m-bg-white {
  background: white;
}
