@import '../../variables.scss';

.ButtonBar {
  margin: 1.2rem 0;
  // the buttons themselves already have a margin.
  margin-bottom: 0.2rem;
  display: flex;

  @media print {
    display: none;
  }

  &.links {
    .button,
    a {
      background-color: unset;
      border: none;
      padding: 0;
      font-weight: inherit;
      color: inherit;
      margin: 0;

      font-size: 14px;
      color: lighten($link-color, 10%);

      margin-bottom: 1rem;
      margin-right: 1em;

      border: 1px solid lighten($link-color, 40%);
      border-radius: 5px;
      padding: 0.2em 0.3em;
      background-color: lighten($link-color, 54%);

      text-decoration: none;

      &:hover {
        color: darken($link-color, 10%);
        border: 1px solid $link-color;
      }

      &:disabled {
        color: lighten($link-color, 35%);
        background-color: lighten($link-color, 84%);
        border-color: lighten($link-color, 40%);
      }
    }
  }

  .button {
    margin-right: 1.8rem;
  }
}
