.EditCounselorUser {
  width: 550px;
  max-width: 90vw;

  .newSchool {
    margin-top: 0.6em;
    font-size: 13px;
  }

  #schoolCity {
    width: 14em;
  }

  #schoolState {
    width: 2em;
  }
}
