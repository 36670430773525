@import '../variables.scss';

.onlyNonNarrow {
  display: none;

  @media (min-width: $narrow-breakpoint) {
    display: block;
  }
}

.scholarships-to-check-list {
  .scholarship {
    text-decoration: none;
    color: inherit;
    padding: 0.6rem 0;
    display: flex;

    > * {
      padding-right: 2rem;
    }

    > *:last-child {
      padding-right: 0;
    }

    &.header {
      font-weight: 500;
      color: $lighter-text-color;

      border-bottom: 3px solid #f5f5f5;
      padding-bottom: 0.2rem;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;
    }

    .name {
      flex-grow: 1;
      min-width: 150px;
      width: 40%;
    }

    .url,
    .flyer,
    .created-at {
      width: 7rem;
      text-align: right;
      @extend .onlyNonNarrow;
    }

    .verified {
      display: block;
      width: 5rem;
      text-align: center;

      label {
        margin: 0 auto;
      }
    }

    .line2 {
      margin-top: 0.3rem;
      font-size: 13px;
    }
  }
}
