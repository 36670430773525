.scholarshipEntryForm {
  &-wrapper {
    min-height: 600px;
    width: 100%;

    .euiTitle {
      text-align: center;
    }
  }

  // highlight error form elements ignoring focused state
  .euiFormLabel.euiFormLabel-isFocused.euiFormLabel.euiFormLabel-isInvalid {
    color: #ca2626; // TODO:IMP: variables from _colors/eui?
  }

  .euiFormRow.m-longLabel {
    .euiFormRow__labelWrapper {
      width: calc(60% - 8px);
    }

    .euiFormRow__fieldWrapper {
      width: 40%;
    }
  }
}
