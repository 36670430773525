@import './variables.scss';

.button {
  padding: 0.4rem 0.6rem;
  font-weight: 500;
  border: none;
  background-color: $link-color;
  border-radius: 3px;
  color: white;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;

  &:disabled {
    color: #b2ceff;
    background-color: #678bc5;
  }

  &:hover {
    background-color: $link-hover-color;
  }

  &.invisible,
  &.link {
    background-color: unset;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  &.link {
    color: $link-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

input,
select {
  padding: $input-vertical-padding 0;
}

textarea {
  padding: 0;
  height: calc(2.8em);
  line-height: 140%;
  // the margin shifts to a border hovering; both need to be excluded from the height.
  box-sizing: content-box;
}

input,
textarea,
select {
  font-size: inherit;
  font-family: inherit;
  font-weight: 500;
  border: none;
  color: inherit;
  border-bottom: 1px solid $input-line-color;
  margin-bottom: 1px;
  width: 100%;

  &:focus {
    outline: none;
    border-bottom: 1px solid #3269a5;
    // margin-bottom: 0px;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='checkbox'] {
    width: auto !important;
  }

  &.invalid {
    border-color: lighten(#ce4014, 5%);
    color: lighten(#ce4014, 5%);
  }
}

select {
  background-color: inherit;
  border-radius: 0;
  -webkit-appearance: none;
}

.field {
  margin-bottom: 0.6rem;

  label {
    display: block;
    color: $light-text-color;
    font-weight: 500;
    font-size: 13px;
    page-break-after: avoid;
  }

  &:hover {
    > label {
      color: #050505;
    }
  }

  &:focus-within {
    > label {
      color: $link-color;
    }
  }

  label.own-line {
    display: block;
    padding-bottom: 0.5rem;
  }

  &.missing-required {
    input {
      border-color: lighten($warning-color, 5%);
    }

    label {
      position: relative;
      color: $warning-color;

      .error {
        position: absolute;
        right: 0;

        // might lie on top of the label...
        background-color: white;
        padding-left: 0.3em;
      }
    }
  }

  // validation error from pattern
  .error {
    color: #ce4014;
    font-weight: 500;
    font-size: 13px;
    margin-top: 0.2rem;
  }

  .value {
    .narrow {
      max-width: 10em;
    }

    .medium-narrow {
      max-width: 24em;
    }
  }

  .help {
    color: $light-text-color;
    font-weight: 500;
    font-size: 13px;
    margin-top: 0.3rem;
  }

  .checkbox {
    display: flex;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    align-items: flex-start;

    input {
      margin: 0;
    }

    label {
      margin-top: 1px;
      color: $light-text-color;
      flex-grow: 1;
      margin-left: 0.6rem;
    }
  }
}
