@import '../variables.scss';

.EditScholarship {
  margin: auto;

  .pageTitle {
    margin-bottom: 0;
  }

  .matchingCount {
    position: fixed;

    bottom: 0;
    right: 0;
    font-size: 12px;

    padding: 0.3em;
    color: darken($orange-color, 10%);
    background-color: rgba(255, 255, 255, 0.8);
  }

  // .columns {
  //   display: flex;
  //   flex-wrap: wrap;

  //   .column {
  //     width: 100%;

  //     @media (min-width: 800px) {
  //       width: 50%;

  //       &:nth-child(even) {
  //         padding-left: 1.4em;
  //       }

  //       &:nth-child(odd) {
  //         padding-right: 1.4em;
  //       }
  //     }
  //   }
  // }

  @media (min-width: 800px) {
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .col {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 800px) {
    .row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .col {
        width: 100%;
      }
    }
  }

  .cols {
    justify-content: flex-start;
    width: 100%;
    padding-left: 1.4em;
  }

  .field {
    margin-top: 1.4rem;
  }

  .help {
    color: $light-text-color;
    font-weight: 500;
    font-size: 13px;
    margin-top: 0.3rem;
  }

  .react-slidedown {
    transition-duration: 0.05s;
    overflow: hidden;
  }

  .customApplyFields {
    .field:first-child {
      margin-top: 0;
    }
  }

  // prevents the page from jumping up and down as the custom apply fields
  // expand/contract
  padding-bottom: 8em;

  h3 {
    font-family: $title-font;
    font-size: 1.4em;
    margin-top: 2em;
  }

  .gpaRange {
    display: flex;
    margin-top: 1.4rem;

    .field {
      width: 8em;
      margin-right: 2em;
      margin-top: 0;

      input {
        width: 3em;
      }
    }
  }

  textarea#description,
  textarea#notes {
    height: 5.6em;
  }

  textarea#reviewCriteria {
    height: 5.6em;
  }

  #minRecommenders {
    width: 50px;
  }

  .college {
    .radio-label {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin: 0.2rem 0;

      label {
        font-size: inherit;
        margin-left: 0.2rem;
      }

      input {
        width: auto;
      }
    }

    .radio-content {
      margin-left: 1.6rem;
      margin-top: 0.6rem;
      margin-bottom: 1.2rem;

      > .field:first-child {
        margin-top: 0;
      }
    }
  }
  .button-align-right {
    justify-content: flex-end;
  }

  .button.m-draft {
    background: #00aa4e;
  }
}

[type='file'] {
  display: none;
}

.custom-upload {
  cursor: pointer;
}
.custom-upload-div {
  background-color: '#dcdee0';
  padding: 0 2rem;
}

.custom-upload-div {
  background-color: '#e6f0e9';
}

.notes-area {
  height: 245px !important;
}
