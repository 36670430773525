.SubmitConfirmation {
  .euiModalHeader__title {
    display: flex;
    flex-direction: row;

    .euiText {
      margin-left: 8px;
      margin-top: 6px;

      color: #e47446;
      font-weight: bold;
    }
  }

  &-checkmark {
    display: inline-block;

    svg {
      width: 50px;
      height: 50px;
      display: block;

      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
          -webkit-animation: dash 0.9s ease-in-out;
          animation: dash 0.9s ease-in-out;
        }
        &.line {
          stroke-dashoffset: 1000;
          -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
          animation: dash 0.9s 0.35s ease-in-out forwards;
        }
        &.check {
          stroke-dashoffset: -100;
          -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
          animation: dash-check 0.9s 0.35s ease-in-out forwards;
        }
      }
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}
