@import '../variables.scss';

.User > .body {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  .content {
    max-width: 800px;

    .columns {
      @media (min-width: $intermediate-breakpoint) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > .column {
          width: 50%;
          padding-right: 1.8rem;
        }

        > .column:last-child {
          padding-left: 1.8rem;
          padding-right: 0 !important;
        }
      }
    }
  }
}
