@import '@elastic/eui/src/components/loading';

// adding m-static modificator for blurring usage
.euiLoadingContent {
  &.m-static {
    .euiLoadingContent__singleLineBackground {
      animation: none;
    }
  }
}
