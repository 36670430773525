.SelfListingHeader {
  max-width: 838px;

  &-title {
    &_1 {
      color: #5295ff;
    }

    &_2 {
      color: #017d73;
    }

    &_2 {
      color: #ca2626;
    }
  }
}
