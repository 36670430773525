.Tooltip {
  position: relative;

  .tooltip-text {
    position: absolute;
    z-index: 8;

    font-size: 13px;
    color: white;
    background-color: #505050;
    padding: 0.5em;
    min-width: 180px;
    margin-top: 10px;
    text-align: center;

    opacity: 0;
    transition: opacity 200ms ease-in-out;

    &.show {
      opacity: 1;
    }

    .arrow {
      $size: 5px;
      position: absolute;
      top: -$size;
      width: 0;
      height: 0;
      border-left: $size solid transparent;
      border-right: $size solid transparent;

      border-bottom: $size solid #505050;
    }

    // next to floating action button
    &.fab {
      right: 90px;
      top: 8px;
      min-width: 120px;
    }
  }
}
