@import "../../../ui/variables";

.RichTextEditor {
  $borderColor: #E7E8ED;

  .rdw-editor-toolbar {
    margin-bottom: -1px;
    border-radius: $euiBorderRadius $euiBorderRadius 0 0;
    border: 1px solid $borderColor;

    .euiButtonGroup{
      margin: 0;
      padding-left: 0;

      &:first-child {
        padding-left: inherit;
      }
    }

    .rdw-link-wrapper {
      .rdw-link-modal {
        height: auto;
      }
    }
  }

  .EditorComponent {
    background-color: #fbfcfd;
    font-family: "Open Sans", sans-serif;
    border-radius: 0;
    line-height: 1.5;
    border: 1px solid $borderColor;
    padding: 12px;
    letter-spacing: normal;
    font-kerning: normal;
    margin: 0;
    resize: none;

    &, & * {
      font-family: inherit !important;
      font-size: 14px !important;
      color: #343741;
      margin: 0;
    }

    & * {
      margin: 0;
      padding: 0;
    }

    .public-DraftStyleDefault-block {
      margin: 0 !important;


      .rdw-link-decorator-wrapper {
        & > a {
          &, &:hover, & * {
            color: #3269a5;
          }
        }

        .rdw-link-decorator-icon {
          display: none;
        }
      }

    }
  }

  .CounterPanel {
    border: 1px solid $borderColor !important;
    border-radius: 0 0 $euiBorderRadius $euiBorderRadius !important;
    margin-top: -1px;

    .euiFlexItem {
      margin: 0;

      .euiBadge {
        background-color: transparent !important;
        padding-left: 0 !important;

        .euiBadge__text span {
          font-weight: 700;
        }

      }

      &:first-child .euiBadge {
        padding-left: 8px !important;
      }
    }
  }

  &.view-mode {
    .rdw-editor-toolbar {
      display: none;
    }

    .EditorComponent {
      padding: 0;
      border: 0;
      background: transparent;
    }

    .CounterPanel {
      margin-top: 1rem;
      border: none !important;
      padding: 0;

      .euiFlexGroup {
        justify-content: flex-end;
      }
    }
  }
}
