// Font sizing extends, using rem mixin
// All line-heights are aligned to baseline grid
// sass-lint:disable no-vendor-prefixes

// Redoing this mixin mainly to add more suitable letter-spacing
@mixin euiFont {
  font-family: $euiFontFamily;
  font-weight: $euiFontWeightRegular;
  letter-spacing: .008em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
}

// Families
$euiFontFamily: 'Montserrat', sans-serif;
$euiCodeFontFamily: Courier, monospace;
$saHeadingFontFamily: 'Montserrat';

// Line height
$euiLineHeight:     1.5;

// Font weights
$euiFontWeightLight:    400;
$euiFontWeightRegular:  500;
$euiFontWeightMedium:   500;
$euiFontWeightSemiBold: 600;
$euiFontWeightBold:     700;


// Font sizes -- scale is loosely based on Major Third (1.250) with a base of 14px
// Base list is an altered scale based on 16px to match the resulted values below
$euiTextScale:    2.125, 1.6875, 1.375, 1.125, 1, .875, .75;

$euiFontSize:     $euiSize - 2; // 14px

$euiFontSizeXS:   floor($euiFontSize * .86);  // 12px // h6
$euiFontSizeS:    floor($euiFontSize * 1);    // 14px // h5 --> Now the same as the base $euiFontSize
$euiFontSizeM:     ceil($euiFontSize * 1.14); // 16px // h4
$euiFontSizeL:     ceil($euiFontSize * 1.57); // 22px // h3
$euiFontSizeXL:   floor($euiFontSize * 1.93); // 27px // h2
$euiFontSizeXXL:  floor($euiFontSize * 2.43); // 34px // h1

$euiBodyLineHeight: 1.142857143; // 16px from a 14px base font size to ensure it aligns to our 16px grid

$euiCodeFontWeightRegular: 500;
$euiFontWeightSemiBold: 600;
$euiCodeFontWeightBold: 700;


$euiTitles: (
  'xxxs': (
    'font-size': $euiFontSizeXS,
    'line-height': lineHeightFromBaseline(2),
    'font-weight': $euiFontWeightBold,
  ),
  'xxs': (
    'font-size': $euiFontSizeS,
    'line-height': lineHeightFromBaseline(3),
    'font-weight': $euiFontWeightBold,
  ),
  'xs': (
    'font-size': $euiFontSizeM,
    'line-height': lineHeightFromBaseline(3),
    'font-weight': $euiFontWeightBold,
  ),
  's': (
    'font-size': $euiFontSizeL,
    'line-height': lineHeightFromBaseline(4),
    'font-weight': $euiFontWeightBold,
  ),
  'm': (
    'font-size': $euiFontSizeXL,
    'line-height': lineHeightFromBaseline(4),
    'font-weight': $euiFontWeightBold,
  ),
  'l': (
    'font-size': $euiFontSizeXXL,
    'line-height': lineHeightFromBaseline(5),
    'font-weight': $euiFontWeightBold,
  ),
);
