@import '../../variables.scss';

$componentWidth: 10rem;

.FormFieldUpload {
  position: relative;
  display: inline-block;
  width: $componentWidth;
  height: $componentWidth;

  .upload-field,
  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed lighten($input-line-color, 10%);

    width: 100%;
    height: 100%;
  }

  .upload-field {
    .clear {
      position: absolute;
      z-index: 10;
      top: -4px;
      right: -4px;

      width: 1.5 * $bubble-width;
      height: 1.5 * $bubble-width;

      svg {
        width: 100%;
        height: 100%;

        fill: lighten($input-line-color, 5%);

        &:hover {
          fill: darken($input-line-color, 10%);
        }
      }
    }

    .icon {
      $iconWidth: 3rem;

      width: $iconWidth;
      height: $iconWidth;
      position: relative;

      svg {
        fill: lighten($input-line-color, 10%);
      }

      svg,
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      .UploadIcon,
      .FileIcon,
      .spinner {
        transition: opacity 0.2s;
        opacity: 0;
      }

      .UploadIcon {
        opacity: 1;
      }

      &.is-uploading {
        .UploadIcon,
        .FileIcon,
        .spinner {
          opacity: 0;
        }

        .spinner {
          opacity: 1;
        }
      }

      &.has-value {
        .UploadIcon,
        .FileIcon,
        .spinner {
          opacity: 0;
        }

        .FileIcon {
          opacity: 1;
        }
      }
    }

    .label {
      color: $light-text-color;
      font-weight: 500;
      font-size: 13px;
      margin-top: 0.6rem;
      width: $componentWidth;
      padding: 0.5rem;
      overflow-wrap: break-word;
      height: 2.5em;
      margin-bottom: -1em;
    }

    transition: background-color, border-color 100ms ease-in-out;
  }

  .error {
    border-color: $warning-color;
    background-color: #fff8f5;
    padding: 0.6rem;

    .message,
    .detail {
      text-align: center;
      color: $warning-color;
    }

    .message {
      font-weight: 500;
    }

    .detail {
      margin-top: 0.5rem;
      font-size: 13px;
    }

    .buttons {
      margin-top: 1.25rem;

      display: flex;

      .retry,
      .cancel {
        svg {
          width: 1.2rem;
          height: 1.2rem;
          fill: $warning-color;
          stroke: $warning-color;
        }

        &:first-child {
          margin-right: 1rem;
        }

        &:hover {
          svg {
            fill: darken($warning-color, 20%);
            stroke: darken($warning-color, 20%);
          }
        }
      }
    }
  }

  &.can-hover:hover {
    .button {
      background-color: lighten($light-background-color, 5%);

      border-color: $input-line-color;

      .icon svg {
        fill: $input-line-color;
      }

      .label {
        color: $dark-text-color;
      }
    }
  }

  input[type='file'] {
    display: inherit;
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
