$light-text-color: #505050;
$dark-text-color: #303030;

@import '../../apply/src/form.scss';
@import 'variables.scss';

* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  color: $light-text-color;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: 500;
  color: black;
}

button {
  &.invisible {
    background-color: unset;
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
}

.pageTitle {
  font-family: $title-font;

  margin-top: 1.2rem;
  margin-bottom: 1.6rem;

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.1em;
    color: $title-contrast-color;

    a {
      color: darken($title-contrast-color, 15%);
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
    margin: 0;
  }
}

.field {
  margin: 0;
  margin-top: 1rem;

  label {
    font-weight: 500;
    overflow: hidden;
    font-size: 13px;
    margin-bottom: 0.4rem;
  }

  .value {
    flex: 1;
    overflow: hidden;

    p {
      margin: 0;
      margin-bottom: 0.3rem;
      line-height: 1.3em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// TODO:IMP: Share between apply/donors
:root {
  --text-blue-color: #5295ff; // euiColorAccent
}
