.LoginSignupFormLayout {
  &-container {
    flex-wrap: wrap;

    > .euiFlexItem {
      transition: opacity 250ms ease, max-width 350ms ease, flex-grow 250ms linear; // animating flex changes between step -1 and first, as well as opacity changes
    }
  }

  &-loginForm {
    display: flex;
    flex-direction: column;

    // 325px is minimal supported width
    min-width: 325px; // to have first registration step a bit wider + avoid layout shift

    margin: 0 auto;

    // todo specify properties and fix layout shift
    transition: all 350ms linear;

    &_signup {
      max-width: 1000px; // to limit 'about you' and possible other huge steps width
      min-height: 552px; // to animate height change at least to some point
      height: auto;

      // lowering margin for signup step headings
      h3 {
        margin: 0;
        line-height: 2rem;
      }
    }

    &_login {
      min-height: 378px;

      // 375px is iPhone 11
      min-width: 375px; // to have first registration step a bit wider + avoid layout shift
    }

    // custom border requested by design discussion
    &.euiPanel.euiPanel--hasShadow {
      box-shadow: 0 0.9px 4px -1px rgba(82, 149, 255, 0.08), 0 2.6px 8px -1px rgba(82, 149, 255, 0.06),
        0 5.7px 12px -1px rgba(82, 149, 255, 0.05), 0 15px 15px -1px rgba(82, 149, 255, 0.04);
    }
  }
}

.LoginSignupFormLayout-loginForm_container {
  // FlexItem which stores login form to have wider proportions when possible(login form)
  flex-basis: auto;
}

// eui 'm' size
@media only screen and (max-width: 991px) {
  // login form to full width, since its being centered
  .LoginSignupFormLayout {
    &-loginForm {
      max-width: 100%;
      margin: 0;
    }
  }
}

// 1341 is custom sizing, thats when our layout is shifting
@media screen and (max-width: 1341px) {
  .euiFlexItem.LoginSignupFormLayout-spacer {
    display: none;
  }
}
