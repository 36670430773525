.LoginSignupForm {
  display: flex;
  flex-direction: column;
  flex: auto;

  &-helpText {
    p {
      margin-bottom: 0 !important;
    }
  }
}

.transition {
  &-fade-enter {
    opacity: 0;
  }

  &-fade-exit {
    opacity: 1;
  }

  &-fade-enter-active {
    opacity: 1;
  }

  &-fade-exit-active {
    opacity: 0;
  }

  &-fade-enter-active {
    transition: opacity 250ms;
  }

  &-fade-exit-active {
    transition: opacity 250ms;
  }
}
