@import './variables.scss';

.scholarshipApplications {
  .content {
    max-width: 1000px;
    margin: auto;

    .operations {
      text-align: right;
      margin-top: -0.6em;
      margin-bottom: 0.6em;

      .button {
        margin-left: 0.8em;
      }
    }
  }

  .description {
    margin-top: -0.2em;
    margin-bottom: 1em;

    font-size: 13px;
  }

  .applicationCycle {
    .euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']) {
      min-width: 100px; // width for app cycles
    }
  }

  .euiStepsHorizontal {
    background: none;
    margin-bottom: 0;
  }

  .euiStepHorizontal {
    cursor: default;
  }

  .euiStepHorizontal__title {
    display: none;
  }

  .applicationsDataGrid {
    min-height: 700px; // when there are low number of applications, the table is too small
    .euiButtonEmpty--text:enabled:focus {
      // link buttons has focus after clicking and going full screen f.e. Looks weird
      background-color: transparent;
    }

    .euiDataGridFooter {
      // hiding popover for footer row
      .euiDataGridRowCell__expandButton {
        display: none;
      }
    }
  }
}
