@import '../variables.scss';

.Donor {
  .content {
    max-width: 800px;
    margin: 0 auto;
  }

  h3 {
    margin-top: 1.4em;
    font-family: $title-font;

    color: $title-contrast-color;
  }
}
