@import '../variables.scss';

.SubmitBanner {
  position: fixed;
  bottom: -$SubmitBanner-height;
  right: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: $PageNavigationMobile-height;
  height: $SubmitBanner-height;

  padding: 1rem;

  box-shadow: 0 -1px 12px rgba(0, 0, 0, 0.08), 0 -1px 4px rgba(0, 0, 0, 0.04);

  @media (min-width: $narrow-breakpoint) {
    margin-bottom: 0;
  }

  &.show {
    bottom: -$SubmitBanner-height;
    transition: bottom 0.5s ease-in-out;

    &.animate {
      bottom: 0;
    }
  }

}
