.scholarshipEntryFormContainer {
  .euiStepHorizontal {
    &__title {
      font-weight: 600;
    }

    &-isSelected {
      .euiStepHorizontal__title {
        font-weight: 700;
      }
    }
  }
}
