@import '../variables.scss';

.reviewsPanel {
  &.empty {
    @media print {
      display: none;
    }
  }

  // Matching legacy view for h2
  h2 {
    margin-top: 32px;
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 3px solid #e0ecff;
    font-family: 'Montserrat';
    font-size: 18px;
    color: #e47446;
  }

  // Notes are displayed as expanded rows, remove borders
  .euiTableRow-isExpandedRow {
    .euiTableRowCell {
      border-top: none;
    }
  }

  .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
    background: transparent;
  }

  // since we are using tooltips as table header, those styles supposed to emulate text-overflow: ellipsis; for tooltip title
  .euiTableHeaderCell {
    .euiToolTipAnchor {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
