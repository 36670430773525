.pastreceitps-thumbanil { 
    background: 50% 50% no-repeat; /* 50% 50% centers image in div */
    width: 200px;
    // height: 200px;
  }
  
  .end {
    margin-left: 185px;
  }

  .icon2 {
    background-color: #578fcc;
    color: white;
    border-radius: 360px;
    width: 20px;
  }