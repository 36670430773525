@import '@elastic/eui/src/components/title';

.euiTitle {
  color: $euiColorTitle;
  word-break: normal;
}

.euiTitle--small {
  line-height: 1.5rem; // instead of 2rem in original eui
  margin: 8px 0px; // instead of 24px? of original titles
}

// temporal modificator for titles which are being migrated into black color
.euiTitle.m-dark {
  color: $euiTitleColor;
}
