.USMap {
  rect {
    fill: white;
    stroke: #e0ecff;
  }

  text {
    fill: #404040;

    &.invert {
      fill: white;
    }
  }

  font-size: 9px;
}
