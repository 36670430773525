@import '../variables';

.ApplyExternal {
  .content {
    max-width: 600px;
    margin: auto;
    margin-top: 2em;
    padding: 0 2em;

    .button {
      margin-top: 1em;
    }

    .expired {
      color: $warning-color;
      font-weight: 500;
    }
  }

  .bullet {
    display: flex;
    margin: 1.6em 0;

    .number {
      width: 34px;
      font-size: 24px;

      font-family: $title-font;
      color: $orange-color;
    }

    .body {
      padding-top: 8px;
      flex: 1;
      overflow: hidden;

      form {
        margin-top: 0.4em;

        .button {
          margin-right: 0.8em;
          font-size: 13px;
          padding: 0.4em 0.6em;
          background-color: initial;
          border: 1px solid darken($title-contrast-color, 10%);
          color: darken($title-contrast-color, 10%);
          outline: none;

          &:hover {
            border: 1px solid darken($title-contrast-color, 25%);
            color: darken($title-contrast-color, 25%);
            background-color: #f8f8f8;
          }
        }

        .help {
          font-size: 13px;
          margin-top: 1.2em;
        }
      }
    }
  }
}
