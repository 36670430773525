@import '../../variables.scss';

.FormFieldState {
  position: relative;

  .candidate {
    padding: $input-vertical-padding 0;

    position: absolute;
    top: 0;
    left: 0;

    .typed {
      opacity: 0;
    }
  }
}
