.StandardFieldSelect {
  margin: 0.8em 0;

  .group {
    .head {
    }

    .body {
      .checkbox {
        margin: 0.5em 0;

        label {
          font-weight: 500;
        }
      }
    }
  }
}
