@import '../variables.scss';

// TODO!!! This scss file was originally moved to @sharedComponents, however its also being included into different components in donors part. This has to be reworked and dropped from dependencies
#root .Application {
  /* There are three widths:
      * narrow: All sections are under each other. Top-level columns are displayed under each other.
        columns inside columns are next to each other. Up to two columns.
      * medium: Sections are under each other. All columns are next to each other, except for wide columns.
        Up to four columns in total.
      * wide: Sections are next to each other (except for wide sections). All columns – including wide ones –
        are next to each other. Up to eight columns in total.

      (we assume no columns are nested no more than two levels deep)

      The CSS is mobile-first; media queries address other widths.
  */
  $wide-breakpoint: 1000px;
  $narrow-breakpoint: 400px;
  $wide-column-gutter: 80px;

  $narrow-page-margin: 0.9rem;

  .body {
    .content {
      max-width: initial;

      padding-top: 1.5rem;

      padding-left: $narrow-page-margin;
      padding-right: $narrow-page-margin;

      > .help {
        font-size: 13px;
        margin-top: 1.6em;
      }

      .receipt {
        display: flex;
        align-items: center;

        .help {
          font-size: 13px;
          margin-left: 1.2rem;
          flex: 1;
        }
      }

      .pageTitle {
        margin-top: 1rem;

        h2 {
          margin-bottom: 0;
        }
      }

      .ButtonBar {
        margin-top: 0;
        align-items: center;

        .status {
          font-size: 14px;
          margin-right: 1em;
          font-weight: 500;
          color: #e47446;
        }

        button {
          margin-bottom: 0;
        }
      }

      @media (min-width: $narrow-breakpoint) {
        padding-right: 1.2rem;
        padding-left: 1.2rem;
      }

      @media (min-width: $narrow-breakpoint + 150px) {
        padding-left: 12vw;
        padding-right: 12vw;
      }

      @media print {
        padding: 0;
      }

      .form {
        @media screen {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        // items at the top level are full width, unless we're at wide resolution.
        > * {
          width: 100%;

          // at a wide resolution, everything at the top level is half-sized
          @media (min-width: $wide-breakpoint and screen) {
            width: 50%;
            padding-right: $wide-column-gutter;
          }
        }

        // ...except for pages
        > .page {
          // the recommenders section puts in several full-width sections
          // that need to be under each other
          flex-wrap: wrap;

          page-break-inside: avoid;

          @media (min-width: $wide-breakpoint and screen) {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-right: 0 !important;

            // inside a page, though, items work like at the top level, i.e. they are half-sized
            > * {
              width: 50%;
              padding-right: $wide-column-gutter;
            }

            // ...except for wide sections
            > .wide {
              width: 100%;
              padding-right: 0;

              // wide columns are also columns (inside the wide-breakpoint media query)
              > .wide-columns {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                > .column {
                  width: 50%;
                  padding-right: 0.6rem;
                }

                > .column:first-child {
                  padding-right: $wide-column-gutter;
                }
              }
            }
          }
        }

        // top level columns only display side-by-side if wider than narrow
        .columns {
          @media (min-width: $narrow-breakpoint), print {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > .column {
              width: 50%;
              padding-right: 0.6rem;
            }
          }
        }

        // other columns always display
        .columns * .columns {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          > .column {
            width: 50%;
            padding-right: 0.6rem;
          }
        }

        .FormSection,
        .FormSupport {
          h2,
          .help,
          .warn {
            page-break-after: avoid;
          }
        }

        .FormSection {
          &.FormTranscript {
            @media print {
              page-break-before: always;

              h2 {
                margin-top: 0;
              }
            }
          }
        }

        .FormSection,
        .list {
          > .help,
          > .warn {
            margin-top: 0.8em;
            margin-bottom: 0.4em;
            font-size: 13px;
          }

          > .warn {
            color: $warning-color;
          }
        }

        // the first level of titles is underlined
        h2 {
          margin-top: 32px;
          margin-bottom: 0rem;
          padding-bottom: 0.3rem;
          border-bottom: 3px solid #e0ecff;

          font-family: $title-font;
          font-size: 18px;
          color: $title-contrast-color;

          @media (max-width: $narrow-breakpoint), print {
            padding-left: $narrow-page-margin;
            margin-left: -$narrow-page-margin;
            margin-right: -$narrow-page-margin;
          }
        }

        // ...subtitles are not
        .title-container .title-container h2 {
          margin-top: 16px;
          border-bottom: none;
          padding-bottom: 0;

          font-family: inherit;
          font-size: inherit;
          color: inherit;
          font-weight: 500;
        }

        .list {
          > .list-item {
            margin-bottom: 0.6rem;
          }
        }
      }
    }
  }
}
