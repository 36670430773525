@import '../../variables';

$animation-time: 100ms;
$max-lines-visible: 12;

.FormFieldAutocomplete {
  position: relative;

  .options {
    transition: background-color $animation-time ease-in-out;

    &.closed {
      opacity: 0;
      max-height: 0;
    }

    &.open {
      opacity: 1;
      max-height: $max-lines-visible * 2.2em;
    }

    position: absolute;
    width: 100%;
    min-width: 400px;
    z-index: 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 1px 4px rgba(0, 0, 0, 0.15);
    transition: opacity $animation-time ease-in-out,
      max-height $animation-time ease-in-out;

    background-color: lighten($light-background-color, 7%);
    overflow: scroll;

    .option {
      text-align: initial;
      padding: 0.5em 0.6em;
      width: 100%;

      .label {
        overflow: hidden;
        width: 100%;
        height: 1.2em;

        .btw {
          font-size: 13px;
          color: $light-text-color;
        }
      }

      &:hover {
        background-color: $light-background-color;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
