.LayoutHeader {
  width: 100%;

  &-top {
    display: flex;
    padding: 48px 16px 48px 24px;

    @media only screen and (max-width: 574px) {
      padding: 24px 8px 12px 12px;
    }
  }

  &-logo {
    &_icon {
      height: 46px;
      width: 200px;

      @media only screen and (max-width: 574px) {
        width: 148px;
        height: auto;
      }
    }
  }

  .euiHeader {
    box-shadow: none;
    border-bottom: none;
    height: 64px;

    background-color: #ffffff;

    &--dark {
      background-color: #e0ecff;
    }
  }

  // Legacy TODO
  $right-margin: 40px;
  $light-text-color: #505050;

  $border-width: 2px;
  $border-color: darken(#e0ecff, 5%);

  &-spacer {
    flex-grow: 1;
    border-bottom: $border-width solid $border-color;
  }

  &-end-spacer {
    width: $right-margin;
    border-bottom: $border-width solid $border-color;
  }

  &-tabs {
    display: flex;
    font-size: 14px;
    overflow-x: auto;
    background-color: #e0ecff; // only applied to dark header

    a {
      text-decoration: none;
      color: inherit;
    }

    .tab {
      padding: 0.6rem 0.3rem;
      color: lighten($light-text-color, 10%);

      white-space: nowrap;

      .counter {
        font-size: 12px;
        background-color: black;
        font-weight: 600;
        color: white;
        border-radius: 99px;
        padding: 1px 5px;
        margin-left: 4px;
        position: relative;
        top: -1px;

        box-shadow: 2px 2px 1px 0 #ddd;
      }

      &.highlight {
        color: black;
      }

      .long {
        display: none;
      }

      @media (min-width: 574px) {
        padding: 0.6rem 0.8rem;

        .long {
          display: inline;
        }

        .short {
          display: none;
        }
      }

      border-bottom: $border-width solid $border-color;
      border-top: $border-width solid #e0ecff;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      transition: background-color 0.1s ease-in-out;

      &:hover {
        color: black;
        background-color: darken(#e0ecff, 2%);
      }

      &.active {
        background-color: white;
        border: $border-width solid $border-color;
        border-bottom: $border-width solid white;
        color: black;
      }
    }
  }

  @media print {
    display: none;
  }
}
