@import '../variables.scss';

.SubmissionForm {
  width: 100%;

  display: flex;

  .drawer {
    margin-top: 1.2rem;
    width: $submission-narrow-drawer-width;
    min-width: $submission-narrow-drawer-width;

    padding-left: $narrow-page-margin / 2;
    display: none;

    @media (min-width: $narrow-breakpoint) {
      display: block;
      padding-left: $page-margin / 2;
      padding-right: $page-margin / 2;
    }

    @media (min-width: $intermediate-breakpoint) {
      width: $submission-intermediate-drawer-width;
      min-width: $submission-intermediate-drawer-width;
      padding-left: $wide-page-margin;
      padding-right: $wide-page-margin;
    }
  }

  .main {
    flex-grow: 1;

    padding-right: $narrow-page-margin;
    padding-left: $narrow-page-margin;
    padding-top: 0.8rem;

    @media (min-width: $narrow-breakpoint) {
      padding-right: $page-margin;
      padding-left: $page-margin;
    }

    @media (min-width: $intermediate-breakpoint) {
      padding-right: 2 * $wide-page-margin;
      padding-left: $wide-page-margin;
    }

    padding-bottom: $PageNavigationMobile-height;

    @media (min-width: $narrow-breakpoint) {
      padding-bottom: 0;
    }

    .instructions {
      h2 {
        margin-top: 0;
      }

      a {
        text-decoration: none;
        color: $dark-text-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h2 {
      margin-top: 2.4rem;
      margin-bottom: 1.2rem;
    }

    .page > .FormSection:first-child > h2 {
      margin-top: 0.6rem;
    }

    h2 ~ div h2 {
      font-size: inherit;
      font-weight: 600;
      color: $light-text-color;
      margin-top: 1.2rem;
    }

    .help {
      color: $light-text-color;
      font-weight: 500;
      font-size: 13px;
      margin-top: 0.3rem;
    }

    .FormSection {
      > .help {
        margin-top: -0.6rem;
        margin-bottom: 0.9rem;
      }
    }

    // top level columns only display side-by-side if wider than column-breakpoint
    .columns {
      @media (min-width: $wide-breakpoint) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > .column {
          width: 50%;
          padding-right: 0.9rem;

          @media (min-width: $wide-breakpoint) {
            padding-right: 2.4rem;
          }
        }

        > .column:last-child {
          padding-right: 0 !important;
        }
      }
    }

    // other columns always display
    .columns * .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > .column {
        width: 50%;
        padding-right: 1.2rem;
      }
    }

    .next-page-button-container {
      padding: 1.8rem 0;

      display: flex;
      justify-content: flex-end;

      .Chevron {
        margin-left: 8px;
        stroke: white;
      }
    }

    form {
      &.hide,
      &.show {
        transition: opacity 50ms ease-in-out;
      }

      &.hide {
        opacity: 1;

        &.animate {
          opacity: 0;
        }
      }

      &.show {
        opacity: 0;

        &.animate {
          opacity: 1;
        }
      }
    }

    .is-submitted,
    .instructions {
      color: $light-text-color;
      margin-top: 0.6rem;
      font-size: 13px;
      margin-top: 1.2rem;
      margin-bottom: 1.8rem;

      p {
        margin: 0;
        margin-bottom: 0.5em;
      }
    }
  }

  &.has-submit-banner .main {
    padding-bottom: $SubmitBanner-height + $PageNavigationMobile-height;
  }

  @media (min-width: $narrow-breakpoint) {
    &.has-submit-banner .main {
      padding-bottom: $SubmitBanner-height;
    }
  }
  .award-button-group{

    display: contents;

  }
  .padding-award-buttongroup{
    padding-left: 1.2rem;
  }
}
