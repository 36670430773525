@import '../variables.scss';

.onlyNonNarrow {
  display: none;

  @media (min-width: $narrow-breakpoint) {
    display: block;
  }
}

.ApplicationList {
  .application {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    padding: 0.6rem 0;
    display: flex;

    > {
      padding-right: 0.3rem;
    }

    &.header {
      font-weight: 500;
      color: $lighter-text-color;

      border-bottom: 3px solid #f5f5f5;
      padding-bottom: 0.2rem;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;

      > div {
        padding-top: 0;
      }
    }

    $highlight-gutter: 0.4rem;

    &.selectable:hover {
      background-color: $hover-background-color;

      padding-right: $highlight-gutter;
      margin-right: -$highlight-gutter;
      padding-left: $highlight-gutter;
      margin-left: -$highlight-gutter;

      .name {
        text-decoration: underline;
        color: $link-color;
      }
    }

    .name {
      width: 40%;
      flex-grow: 1;
      min-width: 150px;
    }

    .highSchool,
    .edited {
      margin-top: 0.3rem;
      font-size: 13px;
    }

    .scholarship {
      margin-left: 0.4rem;
      width: 30%;
      min-width: 150px;

      display: none;

      @media (min-width: $intermediate-breakpoint) {
        display: block;
      }
    }

    .score {
      width: 3rem;
      text-align: right;
      @extend .onlyNonNarrow;
    }

    .submittedAt {
      width: 6rem;
      text-align: right;
      @extend .onlyNonNarrow;
    }

    .supports {
      margin-left: 0.4rem;
      padding-top: 2px;
      width: 5rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      display: none;

      @media (min-width: $narrow-breakpoint) {
        display: flex;
      }

      .icon {
        position: relative;
        top: -2px;
        font-weight: 500;
        font-size: 13px;
        background-color: darken(#e0ecff, 10%);
        color: white;
        padding: 2px 3px;
        margin-left: 0.5em;
      }
    }
  }
}
