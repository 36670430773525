.DocumentsExternal {
  margin-bottom: 3rem;

  h3+.help{
    margin-top: 1rem;
  }

  .filesContainer {
    margin-top: 0.5rem;

    .lorFiles {

      button.upload-field {
        margin-top: 0;
      }
    }
  }
}
