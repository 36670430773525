.Chevron {
  height: 13px;
  width: 13px;
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linejoin: round;

  position: relative;
  top: 1px;
}
