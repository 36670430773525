.ScholarshipListingWizard {
  $wizardMinHeight: 480px;

  &-panel {
    max-width: 740px;
    min-height: $wizardMinHeight;
    overflow-x: hidden; // in order to hide content while animating between steps
    padding: 24px;

    > .euiFlexGroup {
      min-height: $wizardMinHeight;
    }
  }

  .euiButtonEmpty--primary:enabled:focus {
    background: none; // in order to make button link to not have background when in active phase [this might be applied site-wide]
  }

  // ! dirty hack for date selection in inline mode, somehow highlight values are not getting properly highlighted when changed
  .react-datepicker__day--keyboard-selected {
    border: none;
    font-weight: normal;
  }

  .euiDatePickerRange {
    background: none;
    box-shadow: none;
    max-width: 100%;
  }
}
