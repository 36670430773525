@import '../variables.scss';

.EditForm {
  .ErrorMessage {
    .button {
      color: $link-color;
      margin-left: 0.3rem;
      font-weight: 500;
    }
  }

  .radio-label {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin: 0.2rem 0;

    label {
      font-size: inherit;
      margin-left: 0.2rem;
    }

    input {
      width: auto;
    }
  }

  .showStandardFields {
    align-items: center;
    display: flex;

    label {
      margin-left: 0.2rem;
    }
  }

  .textarea-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
    flex: 1;
    align-items: stretch;

    textarea {
      font-size: 14px;
      height: 16px * 1.2 * 20;
      margin-left: 1.4em;
    }

    .line-numbers {
      padding: 0 0;
      position: absolute;
      margin-right: 0.5em;
      height: 100%;

      line-height: 16px * 1.2;

      font-size: 10px;
      font-weight: 500;
      margin-top: 1px;

      color: $input-line-color;
    }

    .buttons {
      position: absolute;
      right: 0;

      .button {
        margin-left: 0.6em;
        color: $link-color;
        font-weight: 500;
        background-color: white;
        padding: 0;

        &:focus {
          outline: 0;
        }

        svg {
          stroke: lighten($link-color, 20%);
          fill: lighten($link-color, 20%);

          &:hover {
            stroke: $link-color;
            fill: $link-color;
          }

          height: 1.2em;
          width: 1.2em;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .JsonValidationError {
    .ErrorMessage {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .fullScreen {
    background-color: white;
    padding: 1em 2em;
    padding-top: 0.4em;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;

    .field {
      flex: 1;
      display: flex;
      flex-direction: column;

      .textarea-wrapper {
        textarea {
          height: auto;
        }
      }
    }
  }

  .draftButtons {
    .button {
      margin-left: 4rem;
      color: $warning-color;
    }

    .button:first-child {
      margin-left: 0;
    }
  }
}
