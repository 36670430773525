.field .AwardAmount {
  margin-top: 0.4rem;

  .checkbox {
    margin-top: 0.4rem;
    margin-bottom: 0;
  }

  .values {
    display: flex;
    align-items: center;

    input {
      margin-left: 0.4em;
      margin-right: 0.4em;

      width: 5em;
      text-align: left;
    }

    input:first-child {
      margin-left: 0;
    }

    .text {
      margin-bottom: 2px;
    }
  }
}
