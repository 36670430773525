@import '../variables.scss';

.QuestionList {
  max-width: 100%;
  $pending-color: #f0f4ff;

  .question {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    margin-bottom: 0.4em;

    &.unanswered {
      background-color: $pending-color;
    }

    &:hover {
      background-color: $hover-background-color;

      &.unanswered {
        background-color: darken($pending-color, 3%);
      }
    }

    &.header {
      font-weight: 500;
      color: $lighter-text-color;

      border-bottom: 3px solid #f5f5f5;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;
    }

    .scholarship {
      color: inherit;
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: underline;
        color: $link-color;
      }
    }

    > * {
      padding: 0.6em;
    }

    .scholarship,
    .applicant {
      padding-left: 0.8em;
    }

    .text {
      flex-grow: 1;
      flex-basis: 0;

      .link {
        font-size: 13px;
        font-weight: 500;
      }

      form {
        margin: 0;

        .link {
          margin-top: 0.3em;
          color: #888;

          .date {
            font-weight: 500;
            font-size: 13px;

            padding-left: 0.3em;
          }
        }
      }

      .a {
        border-left: 3px solid lighten($orange-color, 10%);
        padding-left: 6px;
        margin-top: 0.8em;
      }

      .options-container {
        margin-top: 0.4em;

        & > button {
          margin-left: 0.4rem;

          &:first-child{
            margin-left: 0;
          }
        }
      }
    }

    .scholarship {
      flex: 0.6;
      flex-basis: 0;
      width: 12em;

      display: none;

      @media (min-width: $intermediate-breakpoint) {
        display: block;
      }
    }

    .delete {
      width: 2rem;

      svg {
        margin-top: 1px;
        fill: #ddd;
        stroke: #ccc;
        stroke-width: 6px;
        height: 1.3em;
        width: 1.3em;
        transition: fill, stroke 0.1s ease-in-out;

        &:hover {
          stroke: black;
          fill: black;
        }
      }
    }
  }
}
