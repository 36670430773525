@import '@elastic/eui/src/components/call_out';

.euiCallOut {
  // Success callout to actually have success color scheme
  &--success {
    $color: $euiColorSuccess;
    $backgroundColor: tintOrShade($color, 90%, 70%);

    border-color: $color;
    background-color: tintOrShade($color, 90%, 70%);

    .euiCallOutHeader__icon {
      fill: shadeOrTint(makeHighContrastColor($color, $backgroundColor), 0, 20%);
    }

    .euiCallOutHeader__title {
      color: shadeOrTint(makeHighContrastColor($color, $backgroundColor), 0, 20%);
    }
  }
}
