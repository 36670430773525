.NextPrevNavigation {
  display: flex;

  @media print {
    display: none;
  }

  > .main {
    flex: 1;
  }

  .nav {
    margin: auto 0;
    $size: 2em;

    svg {
      height: $size;
      width: $size;
      fill: #f0f0f0;
      transition: fill 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        fill: #b0b0b0;
      }

      &:last-child {
        margin-left: 1em;
      }
    }
  }

  &.has-buttons .nav {
    margin-left: 1em;
  }
}
