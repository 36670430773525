@import '../variables.scss';

.leaveReviewPanel {
  position: relative;

  @media print {
    display: none;
  }

  &-hide,
  &-open {
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.m-saved {
      background-color: #017D73 !important; // euiColorSuccess
    }
  }

  &-loader {
    position: absolute;
    right: 36px;
  }

  &-input_label {
    max-width: 232px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .euiFormLabel {
    font-size: 14px;
  }

  .euiButtonGroupButton .euiButton__content {
    // criteria button group, making it shrink padding in order to work well on small screens
    padding: inherit;
  }
}
