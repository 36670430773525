.ErrorMessage {
  text-align: center;
  z-index: 11;

  position: fixed;
  width: 400px;
  max-width: 80vw;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 40px;

  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.show {
    opacity: 1;
  }

  .top,
  .bottom {
    border-radius: 10px;
    padding: 0.6rem;
  }

  $color: #ef3b27;

  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16), 0px 0px 4px rgba(0, 0, 0, 0.04);

  .top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $color;
    color: #fff8f5;

    margin: 0;
  }

  .bottom {
    position: relative;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 3px solid $color;
    border-top: none;

    background-color: white;
    color: #ef3b27;
    padding: 1rem;

    .link {
      color: #ef3b27;
      font-weight: 500;
    }

    .unauthorizedUser {
      margin-top: 0.6rem;

      .buttonContainer {
        margin: 0.6rem;
      }
    }
  }

  .close {
    position: absolute;
    bottom: -14px;
    width: 24px;
    height: 24px;
    margin-left: -12px;

    stroke: #ef3b27;
    fill: white;
    transition: fill 0.15s ease-in-out;

    .cross {
      transition: stroke 0.15s ease-in-out;
    }

    &:hover {
      fill: #ef3b27;

      .cross {
        stroke: white;
      }
    }
  }
}
