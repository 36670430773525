// Visualization colors

// Maps allow for easier JSON usage
// Use map_merge($euiColorVisColors, $yourMap) to change individual colors after importing ths file
// The `behindText` variant is a direct copy of the hex output by the JS euiPaletteColorBlindBehindText() function
$euiPaletteColorBlind: (
  euiColorVis0: (
    graphic: #54b399,
    behindText: #6dccb1
  ),
  euiColorVis1: (
    graphic: #6092c0,
    behindText: #79aad9
  ),
  euiColorVis2: (
    graphic: #d36086,
    behindText: #ee789d
  ),
  euiColorVis3: (
    graphic: #9170b8,
    behindText: #a987d1
  ),
  euiColorVis4: (
    graphic: #ca8eae,
    behindText: #e4a6c7
  ),
  euiColorVis5: (
    graphic: #d6bf57,
    behindText: #f1d86f
  ),
  euiColorVis6: (
    graphic: #b9a888,
    behindText: #d2c0a0
  ),
  euiColorVis7: (
    graphic: #da8b45,
    behindText: #f5a35c
  ),
  euiColorVis8: (
    graphic: #aa6556,
    behindText: #c47c6c
  ),
  euiColorVis9: (
    graphic: #e7664c,
    behindText: #ff7e62
  )
);

$euiPaletteColorBlindKeys: map-keys($euiPaletteColorBlind);

$euiColorVis0: map-get(map-get($euiPaletteColorBlind, 'euiColorVis0'), 'graphic');
$euiColorVis1: map-get(map-get($euiPaletteColorBlind, 'euiColorVis1'), 'graphic');
$euiColorVis2: map-get(map-get($euiPaletteColorBlind, 'euiColorVis2'), 'graphic');
$euiColorVis3: map-get(map-get($euiPaletteColorBlind, 'euiColorVis3'), 'graphic');
$euiColorVis4: map-get(map-get($euiPaletteColorBlind, 'euiColorVis4'), 'graphic');
$euiColorVis5: map-get(map-get($euiPaletteColorBlind, 'euiColorVis5'), 'graphic');
$euiColorVis6: map-get(map-get($euiPaletteColorBlind, 'euiColorVis6'), 'graphic');
$euiColorVis7: map-get(map-get($euiPaletteColorBlind, 'euiColorVis7'), 'graphic');
$euiColorVis8: map-get(map-get($euiPaletteColorBlind, 'euiColorVis8'), 'graphic');
$euiColorVis9: map-get(map-get($euiPaletteColorBlind, 'euiColorVis9'), 'graphic');

// sass-lint:disable-block variable-name-format
$euiColorVis0_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis0'), 'behindText');
$euiColorVis1_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis1'), 'behindText');
$euiColorVis2_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis2'), 'behindText');
$euiColorVis3_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis3'), 'behindText');
$euiColorVis4_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis4'), 'behindText');
$euiColorVis5_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis5'), 'behindText');
$euiColorVis6_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis6'), 'behindText');
$euiColorVis7_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis7'), 'behindText');
$euiColorVis8_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis8'), 'behindText');
$euiColorVis9_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis9'), 'behindText');
