@import '../../variables.scss';

.FormNotificationStatus {
  .status {
    font-weight: 500;
    color: $light-text-color;
  }

  .error {
    color: $warning-color;
  }
}
