.PageBuilder {
  &-panel_isActive {
    border: 1px solid rgb(233, 237, 243);
    border-radius: 15px;

    &.euiPanel.euiPanel--isClickable {
      box-shadow: none;

      &:hover,
      &:focus {
        transform: none;
      }
    }
  }
}
