.Banner {
  min-height: 200px;
  background-color: #00C69E;
  overflow: hidden;

  &__mainBlocks{
    position: relative;
    min-height: 200px;
    display: flex;
    padding: 20px;
    justify-content: space-between;

    @media screen and (max-width: 760px){
      flex-direction: column;
    }

    img {
      position: absolute;
      max-width: 200px;
      bottom: 0;
      left: 3%;

      @media screen and (max-width: 920px){
        display: none;
      }
    }

    & > div {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      &.Banner__textWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 200px;
        max-width: 560px;

        @media screen and (max-width: 920px){
          padding: 20px;
        }

        & > div {
          color: #fff;

          &:first-child {
            display: flex;
            align-items: flex-end;
            margin-bottom: 1rem;

            @media screen and (max-width: 380px){
              flex-direction: column;
              align-items: flex-start;
            }

            & > *:first-child {
              font-size: 60px;
              font-weight: 900;
              line-height: 4.2rem;
            }

            & > *:nth-child(2) {
              font-size: 20px;
              font-weight: 700;
              margin-left: 1rem;
              line-height: 1.7rem;

              @media screen and (max-width: 380px){
                margin-left: 0;
                padding-right: 80%;
              }
            }
          }
        }
      }

      &.Banner__buttonWrapper {
        flex-basis: 30%;
        padding: 20px 40px;

        @media screen and (max-width: 760px){
          padding: 20px;
        }

        @media screen and (max-width: 460px){
          padding: 20px 0;
        }

        .euiButton {
          background-color: #FF8E46 !important;
          padding: 1.8rem 1.5rem;
          font-size: 16px;
          font-weight: 900;

          @media screen and (max-width: 460px){
            font-size: 13px;
            padding: 1.8rem 0.5rem;

            svg {
              display: none;
            }
          }
        }
      }
    }
  }
}
