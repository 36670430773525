.RolesDescriptionsBlock {
  align-self: center;
  max-width: 1200px;

  &-title {
    height: 2em;
  }

  &-description {
    height: 6em;
  }

  &-checkmarks {
    padding-left: 2em;
  }

  /** Modificators to handle collapse/extend functionality **/
  .m-transparent {
    opacity: 0;
    transition: opacity 250ms;
  }

  .m-visible {
    opacity: 1;
    transition: opacity 250ms;
  }

  .m-hidden {
    position: absolute;
    z-index: -1; // this block shouldnt overflow anything
  }

  // misc styles tunings
  .euiTabs--condensed .euiTab:focus {
    background-color: transparent;
  }

  .euiTitle {
    color: var(--text-blue-color);

    // main titles for roles
    &--large {
      margin: 1.2rem auto;
    }
  }
}

// eui 'l' size
@media only screen and (max-width: 1199px) {
  .RolesDescriptionsBlock {
    max-width: 100%;
  }
}

// eui 'm'
@media screen and (max-width: 991px) {
  .RolesDescriptionsBlock {
    // making tab labels font size smaller to fit into screen
    .euiTabs--large .euiTab {
      font-size: 14px;
    }
  }
}
