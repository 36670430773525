@import '../variables.scss';

.onlyNonNarrow {
  display: none;

  @media (min-width: $narrow-breakpoint) {
    display: block;
  }
}

.scholarship-list {
  .scholarship {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    padding: 0.6rem 0;
    display: flex;

    > * {
      padding-right: 0.6rem;
    }

    > *:last-child {
      padding-right: 0;
    }

    &.header {
      font-weight: 500;
      color: $lighter-text-color;

      border-bottom: 3px solid #f5f5f5;
      padding-bottom: 0.2rem;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;
    }

    &.selectable:hover {
      background-color: $hover-background-color;
      padding-right: 0.4rem;
      margin-right: -0.4rem;
      padding-left: 0.4rem;
      margin-left: -0.4rem;

      .name {
        text-decoration: underline;
        color: $link-color;
      }
    }

    .name {
      flex-grow: 1;
    }

    .applicationCount {
      width: 4rem;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      .external {
        position: relative;
        font-weight: 500;
        font-size: 13px;
        background-color: #adcdff;
        color: white;
        padding: 2px 3px;
        margin-left: 0.5em;
      }
    }

    .deadline {
      width: 6rem;
      text-align: right;
      @extend .onlyNonNarrow;
    }

    .createdAt {
      width: 6rem;
      text-align: right;
      @extend .onlyNonNarrow;
    }

    .isDraft {
      width: 8rem;
      text-align: right;
      @extend .onlyNonNarrow;

      &-icon {
        width: 32px;
        height: 32px;
      }
    }

    .line2 {
      margin-top: 0.3rem;
      font-size: 13px;
    }
  }
}
