@import '@elastic/eui/src/themes/amsterdam/global_styling/mixins';

// making our shades a bit lower that in eui

@mixin euiBottomShadowSmall($color: $euiShadowColor, $opacity: 0) {
  box-shadow: 0 0.6px 1.2px rgba($color, shadowOpacity(0.07)), 0 1.7px 3.6px rgba($color, shadowOpacity(0.05)),
    0 4.2px 9px rgba($color, shadowOpacity(0.05));

  @if ($opacity > 0) {
    @warn 'The $opacity variable of euiBottomShadowSmall() will be deprecated in a future version of EUI.';
  }
}

@mixin euiBottomShadowMedium($color: $euiShadowColor, $opacity: 0) {
  box-shadow: 0 0.7px 1.4px rgba($color, shadowOpacity(0.07)), 0 1.9px 4px rgba($color, shadowOpacity(0.05)),
    0 4.5px 10px rgba($color, shadowOpacity(0.05)), 0 10px 10px -1px rgba($color, shadowOpacity(0.04));

  @if ($opacity > 0) {
    @warn 'The $opacity variable of euiBottomShadowMedium() will be deprecated in a future version of EUI.';
  }
}
