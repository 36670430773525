.EditDonorUser {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2em;

  #phone {
    width: 12em;
  }

  .DonorWidget {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
