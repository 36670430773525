// This file allows multi-line selectors to make it more readable
// sass-lint:disable single-line-per-selector

/**
 * Adapted from Eric Meyer's reset (http://meyerweb.com/eric/tools/css/reset/, v2.0 | 20110126).
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

// Commented out reset to avoid conflicts with original theme of SA
// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed,
// figure, figcaption, footer, header, hgroup,
// menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
//   margin: 0;
//   padding: 0;
//   border: none;
//   vertical-align: baseline;
// }

// Slowly reverting change above, to have this reset options finally applied to all site one day
p {
  margin: 0;
  padding: 0;
  border: none;
  vertical-align: baseline;
}

code,
pre,
kbd,
samp {
  font-family: $euiCodeFontFamily;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $saHeadingFontFamily; // modified by SA
  font-weight: inherit;
  font-size: inherit;
  margin-block-start: 0; // overriding user agent stylesheet
  margin-block-end: 0; // overriding user agent stylesheet
}

p {
  font-family: $euiFontFamily;
  font-weight: inherit;
  font-size: inherit;
}

input,
textarea,
select,
button {
  font-family: $euiFontFamily;
}

em {
  font-style: italic;
}

strong {
  font-weight: $euiFontWeightBold;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  @include euiFont;
  font-size: $euiFontSize;
  color: $euiTextColor;
  height: 100%;
  // background-color: $euiPageBackgroundColor;
}

body {
  line-height: normal;
}

*:focus {
  outline: none;

  // sass-lint:disable no-vendor-prefixes
  // Disables border that shows up when tabbing in Firefox.
  &::-moz-focus-inner {
    border: none;
  }

  &:-moz-focusring {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: $euiColorPrimary;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
    outline: none;
  }
}

a:hover,
button,
[role='button'] {
  cursor: pointer;
}

input {
  margin: 0;
  padding: 0;

  &:disabled {
    opacity: 1; /* required on iOS */
  }
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: inherit;
  color: inherit;
  border-radius: 0;

  &:hover {
    cursor: pointer;
  }
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; // sass-lint:disable-line no-duplicate-properties
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  margin: 0;
}

fieldset {
  border: none; // custom SA requirement
  min-inline-size: auto;
}
