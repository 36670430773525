@import '@elastic/eui/src/global_styling/variables/_size';

$euiSize: 16px;

$euiSizeXS: $euiSize * 0.25;
$euiSizeS: $euiSize * 0.5;
$euiSizeM: $euiSize * 0.75;
$euiSizeL: $euiSize * 1.5;
$euiSizeXL: $euiSize * 2;
$euiSizeXXL: $euiSize * 2.5;

$euiButtonMinWidth: $euiSize * 7;

$euiScrollBar: $euiSize;
$euiScrollBarCorner: $euiSizeS * 0.75;

// Corner sizes are used as an inset border and therefore a smaller corner size means a larger thumb
$euiScrollBarCorner: $euiSizeXS;
$euiScrollBarCornerThin: $euiSizeS * 0.75;
