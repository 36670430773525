.Applications {
  > .body {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .description {
      margin-top: 0.4em;
      margin-bottom: 2em;

      p {
        margin: 0;
        margin-bottom: 0.2em;
      }
    }

    > .right {
      margin-left: 3rem;

      display: none;
      @media (min-width: 900px) {
        display: block;
      }

      .chart {
        background-color: #fafcff;

        padding: 0.6rem;
        margin-bottom: 2.4rem;

        .label {
          color: #808080;
          margin-top: 0.6rem;
          font-size: 14px;
        }
      }
    }

    > .content {
      justify-content: left;
      padding: 0.3rem 1rem;
    }
  }
}
