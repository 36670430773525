.PopOver {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(#ccc, 0.7);
  z-index: 10;
  padding: 1em 2em;

  > .content {
    padding: 2em;
    background-color: white;

    max-width: 100%;
    max-height: 100%;

    overflow: scroll;
  }
}
