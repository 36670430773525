.ScholarshipsToInvite {
  padding: 12px 0;

  &-card {
    position: relative;
    background: #ffffff;
    border: 1px solid #f5f7fa !important;
    transition: border-color 0.25s ease-in-out;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px;

    &-name {
      font-weight: 600;

      /* Core/Primary - $euiColorPrimary */
      color: #0077cc;
    }

    &-selectionIcon {
      position: absolute;
      top: -10px;
      left: -10px;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      background: #fbfcfd;
      border-radius: 14px;
    }

    &_isActive {
      background: linear-gradient(0deg, rgba(227, 240, 249, 0.37), rgba(227, 240, 249, 0.37)), #ffffff;

      /* Core/Primary - $euiColorPrimary */
      border: 1px solid #0077cc !important;
      box-shadow: 0px 2.7px 9px rgba(0, 119, 204, 0.2) !important;

      .ScholarshipsToInvite-card-selectionIcon {
        opacity: 1;
      }
    }
  }
}
