@import '@elastic/eui/src/components/panel';

.euiPanel.euiPanel--isClickable:hover,
.euiPanel.euiPanel--isClickable:focus {
  transform: translateY(-1px); // 2px originally
}

.euiPanel.euiPanel--borderRadiusMedium {
  border-radius: 12px;
}
