@import '@elastic/eui/src/components/form';

.euiTextArea {
  // fixing compatibility with SA styles and textareas going out of the layout
  box-sizing: border-box;

  // Fixing hover effects on disabled text areas
  &:disabled {
    &:hover {
      margin-bottom: 1px;
    }
  }
}

// Fixing active select options to have proper font color
.euiFilterSelectItem:focus,
.euiFilterSelectItem-isFocused {
  color: #fff;
}

// At some point someone added 'display:none' into SA styles to those types of inputs which totally messes Eui file picker.
.euiFilePicker__input[type='file'] {
  display: inherit;
}

// SA
.euiFieldText::placeholder,
.euiFieldPassword::placeholder {
  color: #c1c1c1;
  opacity: 1; /* Firefox */
}

.euiFormControlLayoutCustomIcon {
  color: #c1c1c1; // to match placeholder color
}

/** File picker */
.euiFilePicker {
  /* Table/Selected */
  background: rgba(227, 240, 249, 0.37);

  /* Default/Secondary */
  border: 2px dashed rgba(0, 191, 179, 0.2);
  border-radius: 6px;

  /* Core/Full - $euiColorFullShade */
  color: #000000;

  &__prompt {
    box-shadow: none;
  }

  // custom color for filepicker progressbar
  .euiProgress--accent.euiProgress--indeterminate:before {
    background-color: $euiColorSuccess;
  }
}
