@import '../variables.scss';

.FormSupport {
  .missing {
    color: $warning-color;
  }

  .message {
    margin-top: 0.6em;
  }

  .FileIcon {
    height: 0.8em;
    width: 0.8em;
    fill: $link-color;
    margin-top: 0.15em;
    margin-right: 0.2em;
  }

  .Support {
    .help {
      margin-top: 0.8em;
      margin-bottom: 0.4em;
      font-size: 13px;
    }
  }

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#ccc, 0.7);

    .popup {
      padding: 2em;
      background-color: white;

      max-height: 100vh;
      max-width: 100vh;

      overflow: scroll;
    }
  }
}
