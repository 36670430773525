.BackgroundFileUploader {
  min-width: 240px;
  overflow: hidden;

  .fileInfo {
    overflow: hidden;

    & > .euiFlexItem:first-child {
      max-width: 100%;

      .fileName {
        max-width: 100%;
        padding: 0 1rem;

        & > div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .euiPanel {
    /* Focus/Text */
    background: rgba(105, 112, 125, 0.1);

    /* Core/Success - $euiColorSuccess */
    border: 2px dashed #00bfb3;
    border-radius: 6px;
  }

  .euiEmptyPrompt--vertical .euiEmptyPrompt__main {
    min-height: auto;
  }
}
