// This extra import allows any variables that are created via functions to work when loaded into JS
@import '../global_styling/functions/_index';

// These colors stay the same no matter the theme
$euiColorGhost: #fff;
$euiColorInk: #000;

// Core
$euiColorPrimary: #0077cc;
$euiColorAccent: #fc7049;
$euiColorTitle: $euiColorAccent; // eventually shall be black? based on counselors redesign

// Status
$euiColorSuccess: #00bfb3;
$euiColorDanger: #bd271e;
$euiColorWarning: #fec514;

// Grays
$euiColorEmptyShade: #fff;
$euiColorLightestShade: #f5f7fa;
$euiColorLightShade: #e9edf3;
$euiColorMediumShade: #98a2b3;
$euiColorDarkShade: #69707d;
$euiColorDarkestShade: #343741;
$euiColorFullShade: #000;

// Backgrounds
$euiPageBackgroundColor: tint($euiColorLightestShade, 50%);
$euiColorHighlight: #fffcdd;

// Every color below must be based mathematically on the set above and in a particular order.
$euiTextColor: $euiColorDarkestShade;
$euiTitleColor: shade($euiTextColor, 50%); // ? conflict with $euiColorTitle?
$euiTextSubduedColor: makeHighContrastColor($euiColorMediumShade);
$euiColorDisabled: tint($euiTextColor, 70%);

// Contrasty text variants
$euiColorPrimaryText: makeHighContrastColor($euiColorPrimary);
$euiColorAccentText: makeHighContrastColor($euiColorAccent);
$euiColorWarningText: makeHighContrastColor($euiColorWarning);
$euiColorDangerText: makeHighContrastColor($euiColorDanger);
$euiColorDisabledText: makeDisabledContrastColor($euiColorDisabled);
$euiColorSuccessText: makeHighContrastColor($euiColorSuccess);
$euiLinkColor: $euiColorPrimaryText;

// Charts
$euiColorChartLines: shade($euiColorLightestShade, 3%);
$euiColorChartBand: $euiColorLightestShade;
