.FloatingActionButton {
  background-color: #3269a5;
  border-radius: 50%;
  bottom: 6vh;
  box-shadow: 0 0 2px 2px rgba(162, 162, 162, 72%);
  cursor: pointer;
  display: flex;
  height: 64px;
  position: fixed !important; // FIXME, not sure when this went wrong, but FloatingAction + Tooltip styles getting overrided
  right: 6vw;
  width: 64px;
  transition: background-color 250ms ease-in-out, transform 250ms ease-in-out, background 250ms ease-in-out;

  &:hover {
    background-color: darken(#3269a5, 10%);
    transform: translate(0, -1px);
  }

  button.invisible {
    margin: auto;
    display: flex;
    outline: none;
    height: 64px;
    width: 64px;

    svg {
      margin: auto;
      width: 32px;
      height: 32px;
    }
  }
}
