@import '../variables.scss';

.checkbox {
  display: flex;

  &-input {
    position: absolute;
  }

  &-text {
    // Emulating hanging indents
    display: inline-block;
    padding-left: 1.5em;
    text-indent: 0;
    width: 100%;
  }
}
