.scholarship {
  line-height: normal;

  &-content {
    margin: auto;
    max-width: 1200px;
  }

  // Just bunch of hard styles to make current view of form prettier
  .field {
    label {
      margin-bottom: 6px;
      line-height: normal;
    }

    .form {
      line-height: normal;
    }
  }

  .euiDatePickerRange {
    box-shadow: 0 0 transparent, inset 0 0 0 1px rgb(126, 173, 243); // Workaround to have styling look more relevant to old one. Should exist here until Scholarship view will be reworked with Eui
  }
}
