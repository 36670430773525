.applicationsCategories {
  .euiKeyPadMenuItem {
    .euiKeyPadMenuItem__badgeWrapper {
      position: absolute;
      top: -10px;
      right: -26px;
      z-index: 3;
    }
  }

  // adjusting width for key pad badges to be able to handle 5x digits
  .euiKeyPadMenuItem__badge {
    width: auto;
    min-width: 2rem;
  }

  .euiKeyPadMenuItem--hasBetaBadge
    .euiKeyPadMenuItem__inner
    .euiKeyPadMenuItem__badgeWrapper
    .euiKeyPadMenuItem__badge:not(.euiBetaBadge--iconOnly) {
    letter-spacing: normal;
  }

  .euiKeyPadMenuItem {
    // to get category name displayed in single line
    width: auto;
    min-width: 116px;
    max-width: 146px;
    padding: 2px 8px;

    .euiKeyPadMenuItem__badgeWrapper {
      .euiKeyPadMenuItem__badge {
        width: auto;
      }
    }
  }

  // Showing currently active categories
  .euiKeyPadMenuItem:not(:disabled).isActive {
    background-color: rgba(50, 105, 165, 0.1);
    border: none;
  }

  .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):hover,
  .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus,
  .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus-within {
    text-decoration: none;
  }

  .euiBetaBadge.euiBetaBadge--small {
    font-size: 12px;
    padding: 0 8px;
  }
}
