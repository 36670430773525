.TemplatePreviewModal {
  min-width: 8.5in !important;

  .euiModalBody__overflow {
    padding: 0;
    margin: 0;
    overflow: scroll;
    mask-image: none;
  }
}
