.RecurrentNotificationEmailConfiguration {
  &-editor {
    // TODO:IMP: This has to be fixed for overall EUI + SA combination at higher level
    .euiMarkdownEditorTextArea {
      width: auto !important;
      border-bottom: 1px solid #d3dae6;
    }

    > .euiMarkdownEditorToolbar > .euiMarkdownEditorToolbar__buttons {
      // we are forced to hjack toolbar for md editor in order to hide some components which are not suitable for our draft editor.
      // unfortunately its not configurable yet in EUI
      > span:nth-child(4),
      > span:nth-child(5),
      > span:nth-child(6),
      > span:nth-child(7),
      > span:nth-child(8),
      > span:nth-child(9),
      > span:nth-child(12) {
        display: none;
      }

      // since we have no way to extend content of control buttons for panel, we set it this way :(
      > span:nth-child(13) > button {
        width: 106px;
        color: #2e5f95;
        background-color: rgba(50, 105, 165, 0.2);
        padding: 6px 16px;
      }

      > span:nth-child(13) > button::before {
        content: 'Name';
      }

      > span:nth-child(14) > button {
        width: 146px;
        color: #2e5f95;
        background-color: rgba(50, 105, 165, 0.2);
        padding: 6px 12px;
      }

      > span:nth-child(14) > button::before {
        content: 'Award Amount';
      }

      > span:nth-child(15) > button {
        width: 120px;
        color: #2e5f95;
        background-color: rgba(50, 105, 165, 0.2);
        padding: 6px 12px;
      }

      > span:nth-child(15) > button::before {
        content: 'Deadline';
      }
    }

    .euiMarkdownEditorFooter {
      display: none;
    }
  }
}
