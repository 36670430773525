@import '../variables.scss';

.SearchContainer {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.6em;

  input {
    max-width: 13em;
  }

  svg.cancel {
    background-color: inherit;
    cursor: pointer;
    stroke: $input-line-color;
    z-index: 2;
  }

  svg {
    fill: $input-line-color;
    padding-top: $input-vertical-padding + 0.1rem;
    padding-bottom: $input-vertical-padding + 0.1rem;
    box-sizing: content-box;
    margin-left: -1em;
    height: 1em;
    width: 1em;
    background-color: white;
  }
}
