.DonorWidget {
  $radio-width: 1.8rem;

  .radio-label {
    display: flex;
    margin-bottom: 0.3em;

    > input {
      width: $radio-width;
      margin: 0;
    }
  }

  .field {
    margin-top: 0.8rem;
  }
  .fields {
    margin: 32px 0 0 -48px;
    flex-grow: 1;
  }

  .fieldContainer {
    display: flex;
    padding-left: $radio-width;

    #donorId {
      width: 4em;
    }

    #donorName {
      flex: 1;
    }

    .field {
      flex: 1;
      margin-left: 1em;

      &:first-child {
        margin-left: 0;
      }
    }

    .donorName {
      margin-left: 1.5em;

      .value {
        margin-top: 0.8em;
      }
    }
  }
}

.donor-autocomplete{
  width: 400px;
  min-width: 180px;
}

.flex {
  display: flex;
}

.donor-id {
  margin-top: -44px;
}