@import '../variables.scss';

$intermediate-breakpoint: ($wide-breakpoint + $narrow-breakpoint) /2;

.ViewScholarship {
  .help {
    color: $light-text-color;
    font-weight: 500;
    font-size: 13px;
    margin-top: 0.3rem;
  }

  .any {
    color: #c0c0c0;
  }

  .columns {
    display: flex;
    flex-wrap: wrap;

    .column {
      width: 100%;

      @media (min-width: 800px) {
        width: 50%;

        &:nth-child(even) {
          padding-left: 1.4em;
        }

        &:nth-child(odd) {
          padding-right: 1.4em;
        }
      }
    }
  }

  .form {
    p:first-child {
      margin-top: 0.2em;
    }
  }

  a {
    text-decoration: none;
    color: $link-color;

    &.clamp {
      display: block;
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .instructions {
    p {
      margin: 0;
      margin-bottom: 0.5em;
    }
  }

  h3 {
    font-family: $title-font;
    font-size: 1.4em;
    margin-top: 1.4em;
  }

  #eligibility {
    ul {
      margin: 0.4em 0;

      li {
        margin: 0.2em 0;
      }
    }
  }

  .reviewCriteria {
    .header {
      color: black;
      font-size: 13px;
      margin-bottom: 0.4em;
    }

    .criterion {
      display: flex;
      margin-bottom: 0.4em;

      .name {
        flex: 1;
        max-width: 300px;
        overflow: hidden;
      }

      .weight,
      .scale {
        text-align: right;
        width: 60px;
      }
    }
	}
	.pastRecipients{
		padding: 1rem 0
	}
}
