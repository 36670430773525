.DisappearingBanner {
  position: fixed;

  $height: 50px;

  top: -$height;
  left: 0;
  right: 0;

  background-color: white;
  padding: 13px;

  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08), 0 1px 4px rgba(0, 0, 0, 0.04);

  display: flex;
  align-items: center;

  .Warning {
    margin: 0 0.6rem;
  }

  &.show {
    top: -$height;
    transition: top 0.5s ease-in-out;

    &.animate {
      top: 0;
    }
  }

  &.hide {
    top: 0;
    transition: top 0.5s ease-in-out;

    &.animate {
      top: -$height;
    }
  }

  &.hidden {
    display: none;
  }
}
