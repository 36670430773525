@import '../../src/variables';

.DonorHomepage {
  $pageBackgroundColor: #f0f5ff;
  $textColorGray: #95a0b3;
  $gridGap: 20px;
  $defaultStatBlockPadding: 12px;

  min-height: calc(100vh - 75px);
  box-sizing: border-box;
  background-color: $pageBackgroundColor;
  border-top: 1px solid #e6eefd;

  &__content {
    padding: 45px;
    max-width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 470px) {
      padding: 25px;
    }
  }

  &__controls {
    margin: 0 0 1rem;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;

    & > * {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .DonorHomepage__dropdownControls {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0;

      @media screen and (max-width: 767px) {
        margin-left: 12px;
      }

      & > .euiFlexGroup {
        @media screen and (min-width: 768px) {
          flex-direction: row;
        }

        & > .euiFlexItem {
          &.DonorHomepage__scholarshipName {
            overflow: hidden;
            text-align: center;
            max-width: 100%;

            @media screen and (min-width: 768px) {
              text-align: left;
              margin-right: 1rem;
            }

            .euiLoadingSpinner {
              margin: 4px 0;
            }

            h1 {
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            & > .euiPopover {
              overflow: hidden !important;
              max-width: 100% !important;
              white-space: nowrap;
              margin-top: 1px;

              & > .euiPopover__anchor {
                max-width: 100%;

                & > h1 {
                  display: flex;
                  align-items: center;

                  @media screen and (max-width: 767px) {
                    white-space: initial;
                    flex-wrap: wrap;
                    justify-content: center;
                  }

                  & > * {
                    flex-grow: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  svg {
                    margin: 0 6px;
                  }
                }
              }

              h1 {
                &:hover,
                &:focus {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
            }
          }

          &.DonorHomepage__dateRangeFilter {
            @media screen and (max-width: 767px) {
              margin-top: 0px;
            }

            .euiButtonEmpty {
              &,
              & * {
                color: $blue-color !important;
                font-weight: 700;
              }

              svg {
                fill: $blue-color !important;
              }
            }
          }
        }
      }
    }

    .DonorHomepage__newScholarshipLink {
      margin-right: 0;
      white-space: nowrap;

      @media screen and (max-width: 767px) {
        margin: 12px !important;
      }

      a {
        text-align: center;
        color: $blue-color;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .Banner {
    margin-bottom: $gridGap;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr auto;
    grid-gap: 20px;

    @media screen and (max-width: 1100px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    & > * {
      min-height: 590px;
      background-color: #fff;

      @media screen and (min-width: 880px) and (max-width: 1100px) {
        min-height: 300px;
      }

      &:nth-child(2) {
        background-color: $pageBackgroundColor;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;

        @media screen and (min-width: 880px) and (max-width: 1100px) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: auto;
        }

        @media screen and (max-width: 470px) {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }

        & > * {
          background-color: #fff;

          &:first-child {
            grid-column: 1/3;

            @media screen and (max-width: 470px) {
              grid-column: 1/2;

              * {
                text-align: center;
              }
            }
          }
        }
      }

      &:nth-child(5) {
        grid-column: 1/3;

        @media screen and (max-width: 1100px) {
          grid-column: auto;
        }
      }
    }
  }

  &__statBlock {
    border: 1px solid #dae7ff;
    display: flex;
    align-items: stretch;

    &Header {
      min-height: 68px;
      margin: 12px 12px 0 !important;
      padding: 12px 24px;
      border-bottom: 1px solid #dae7ff;

      & > .euiFlexGroup {
        justify-content: flex-start;
        align-items: center;

        & > .euiFlexItem {
          &:first-child {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;

            .EuiIcon {
              margin-left: -24px;
            }
          }
        }
      }
    }

    &Body {
      margin: 0 12px 12px !important;
    }

    &EmptyContent {
      padding: 48px 24px;

      .DonorHomepage__statBlockError {
        padding: 0 !important;
      }
    }
  }

  &__statInfo {
    & > .euiFlexItem {
      margin-top: 0;
      margin-bottom: 0;

      &.DonorHomepage__statNumber {
        margin-bottom: 10px;
      }

      &.DonorHomepage__statName {
        margin-bottom: 15px;
      }
    }
  }

  &__statNumber {
    font-size: 42px;
    font-weight: 900;

    @media screen and (max-width: 470px) {
      align-items: center;
    }

    .euiLoadingSpinner {
      margin: 16px 0px;
    }
  }

  &__statName {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__statDescription {
    font-size: 16px;
    color: $textColorGray;
  }

  &__statBlockError {
    padding: 24px;
  }

  &__basicTable {
    .euiTableRow {
      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        padding: 8px 16px !important;
      }
    }

    .euiTableCellContent {
      padding: 12px 24px;

      @media screen and (max-width: 767px) {
        padding: 12px 8px;
      }
    }

    thead {
      background-color: #fafdff;
      * {
        text-transform: uppercase;
        font-size: 14px !important;
        color: $textColorGray;
      }
    }
  }

  .pageViewsBlock {
    & > * {
      margin: $defaultStatBlockPadding;
    }

    &__image {
      flex-basis: 30%;

      @media screen and (min-width: 880px) and (max-width: 1100px) {
        display: none;
      }

      @media screen and (max-width: 675px) {
        display: none;
      }

      img {
        opacity: 0.5;
        -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      }
    }

    &__statData {
      flex-basis: 60%;

      @media screen and (max-width: 1100px) {
        margin-top: 36px;
      }
    }

    &__links {
      @media screen and (max-width: 470px) {
        justify-content: center;
        margin-top: 24px;
      }

      & > .euiFlexGroup {
        align-items: flex-end;
        justify-content: space-between;

        @media screen and (max-width: 470px) {
          flex-direction: column;
          align-items: center;
          flex: 0 0 20%;
        }

        & > .euiFlexItem {
          &,
          & * {
            color: $blue-color;
            text-align: center;
            white-space: nowrap;
          }
        }

        .euiLink-disabled {
          color: #95a0b3;
        }
      }
    }
  }

  .pageSuggestionsBlock,
  .pageClicksBlock {
    & > .euiFlexGroup {
      justify-content: space-between;
    }

    &__statData {
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 0;

      & > .euiFlexGroup {
        margin: 12px 12px 0;
        text-align: center;

        .DonorHomepage__statDescription {
          margin-bottom: 24px;
        }

        .DonorHomepage__statNumber {
          align-items: center;
        }
      }
    }

    &__chart,
    &__emptyChartBlock {
      flex: 0 0 50px;
      display: flex;
      justify-content: flex-end;
      padding: 24px;
    }

    &__chart {
      padding-top: 0;
    }

    &__emptyChartBlock {
      text-align: center;
      font-size: 16px;
      color: $textColorGray;
      font-style: italic;
    }
  }

  // ? move those to moduled scss files
  .newQualifyingStudentsNumberBlock {
    & > .euiFlexGroup {
      @media screen and (min-width: 880px) and (max-width: 1100px) {
        flex-direction: row;
      }

      & > .euiFlexItem {
        flex-basis: 50%;

        @media screen and (max-width: 1100px) {
          &:first-child {
            flex-basis: 40%;
            margin-bottom: 18px;
          }

          &:nth-child(2) {
            flex-basis: 60%;
          }
        }
      }
    }

    &__statData {
      justify-content: center;

      @media screen and (max-width: 879px) {
        margin-top: 36px;
      }

      @media screen and (max-width: 470px) {
        * {
          text-align: center;
        }
      }

      & > .euiFlexGroup {
        margin: 24px;

        @media screen and (max-width: 879px) {
          margin: 12px;
        }

        .DonorHomepage__statNumber {
          @media screen and (min-width: 881px) {
            font-size: 71px;

            .euiLoadingSpinner {
              width: 28px;
              height: 28px;
              margin: 34px 0px;
            }
          }
        }
      }
    }

    &__chart,
    &__emptyChartBlock {
      margin: 36px;
    }

    &__chart {
      &:hover {
        cursor: pointer !important;
      }

      & > .echChart {
        min-height: 100%;
      }
    }

    &__modalBtn {
      align-self: flex-end;
      margin-bottom: 0;

      svg {
        fill: $blue-color;
      }
    }

    &__chartContainer {
      margin-right: -6px;
    }

    &__emptyChartBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 16px;
      color: $textColorGray;
      font-style: italic;
    }
  }

  // ? move those to moduled scss file
  .questionsBlock {
    &__question {
      margin: 36px 36px 12px;
    }

    &__answer {
      margin: 12px 36px 36px;
    }

    &__author,
    &__date,
    &__replyText {
      font-size: 12px;
      color: $textColorGray;
    }

    &__author {
      margin: 6px 12px 9px;
    }

    &__text {
      padding: 12px 18px;
      background-color: #e7f0ff;
      border-radius: 10px;
      color: $textColorGray;
      font-weight: 500;
      margin: 0 12px 9px;
      overflow: hidden;

      max-height: 220px;
      overflow-y: auto;
    }

    &__date {
      margin: 0 12px 12px;
      text-transform: uppercase;
      text-align: right;
    }

    &__replyText {
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &__textareaWrapper {
      margin-top: 9px;
      margin-bottom: 0;

      & > .euiTextArea {
        height: 145px;
        overflow: hidden;
      }
    }

    &__replyButton {
      margin-top: 12px;
      align-items: flex-start;
    }
  }

  .newQualifyingStudentsListBlock,
  .studentDocumentationBlock {
    .euiPagination {
      align-self: center;
      margin: 0 12px 8px;

      @media screen and (max-width: 767px) {
        margin: 0 24px 24px;
      }
    }
  }

  // ? move those to moduled scss files
  .newQualifyingStudentsListBlock {
    &__headerIcon {
      margin-left: 0;

      img {
        width: 25px;
        margin-top: 6px;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    .hiddenValue {
      font-size: 0;
      background-color: #e9ecef;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 5px;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .euiPagination {
      align-self: center;
      margin: 0px 12px 8px;

      @media screen and (max-width: 767px) {
        margin: 0 24px 24px;
      }
    }
  }

  // ? move those to moduled scss files
  .studentDocumentationBlock {
    min-height: auto;

    &__downloadFile {
      width: 35px;

      img {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
}

// ? move those to moduled scss files

.upgradeToPremiumTooltip {
  &__content {
    max-width: 500px;
    text-align: left;

    @media screen and (max-width: 767px) {
      max-width: 300px;
    }

    @media screen and (max-width: 450px) {
      max-width: 50vw;
    }

    a {
      color: $blue-color;
    }
  }
}
