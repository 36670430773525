.StudentInvitations {
  align-self: center;
  padding: 0 24px;

  // L+ max width limitations which may be handled better way
  @media only screen and (min-width: 575px) {
    min-width: 500px;
    max-width: min-content;
  }

  // L+ max width limitations which may be handled better way
  @media only screen and (min-width: 992px) {
    min-width: 840px;
    max-width: min-content;
  }

  &-description {
    padding-left: 16px;
    padding-bottom: 16px;
  }

  @media only screen and (max-width: 574px) {
    .euiFlexGroup--responsive > .euiFlexItem,
    .euiFlexGrid--responsive > .euiFlexItem {
      // mobile view margins for responsive blocks
      margin-bottom: 0px !important;
    }
  }
}
