.ReviewerStatusModal {
  min-width: 500px;

  .euiModalBody {
    &__overflow{
     padding: 1rem 1.75rem;

      .reviewerData {

        .euiAccordion {
          padding-right: 32px;

          .euiAccordion__button {
            display: none;
          }
        }
      }
    }
  }
}
