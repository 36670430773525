.PageContentWrapper.euiPage--paddingMedium {
  padding: 8px;
}

// Eui 'xs'
@media only screen and (max-width: 574px) {
  .PageContentWrapper.euiPage--paddingMedium {
    padding: 0;
  }
}
