@import '@elastic/eui/src/components/button';

// Forcing white color for buttons, even if its not passing AA requirements
.euiButton--fill:not([class*='isDisabled']) {
  color: $euiColorGhost !important;
}

.euiButton {
  font-weight: 600;
}

// making paddings match design - may be solved witn variabling instead?
.euiButtonGroup {
  padding: 0;
}

.euiButtonGroup--compressed .euiButtonGroupButton .euiButton__content {
  padding-left: 28px;
  padding-right: 28px;
}

.euiButtonEmpty--primary:enabled:focus {
  background-color: transparent !important;
}

.euiButtonEmpty--primary:focus {
  background-color: transparent !important;
}
