@import '@elastic/eui/src/components/header';

.euiHeader {
  border-bottom: none;
  background-color: #FFF;

  &--dark {
    background-color: #e0ecff;

    .euiHeaderLogo__text, .euiHeader--dark .euiHeaderLink, .euiHeader--dark .euiHeaderSectionItemButton {
      color: black;
    }
  }
}
