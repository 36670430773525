@import '../variables.scss';

.onlyNonNarrow {
  display: none;
  margin: auto;

  @media (min-width: $narrow-breakpoint) {
    display: block;
  }
}

// ? what is that component here
.ScholarshipList {
  .scholarship {
    text-decoration: none;
    color: inherit;
    padding: 0.6rem 0;
    display: flex;

    > * {
      padding-right: 0.6rem;
    }

    > *:last-child {
      padding-right: 0;
    }

    &.header {
      font-weight: 500;
      color: $lighter-text-color;

      border-bottom: 3px solid #f5f5f5;
      padding-bottom: 0.2rem;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;
      display: flex;
      justify-content: space-between;
    }

    &.list {
      font-weight: 300;
      // color: $lighter-text-color;

      padding-bottom: 0.2rem;
      margin-bottom: 0.6rem;

      // makes it fill the screen width on mobile. should be fixed in a cleaner way.
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-left: -0.3rem;
      margin-right: -0.3rem;
      display: flex;
      justify-content: space-between;
    }

    &.selectable:hover {
      background-color: $hover-background-color;
      padding-right: 0.4rem;
      margin-right: -0.4rem;
      padding-left: 0.4rem;
      margin-left: -0.4rem;

      .namee {
        text-decoration: underline;
        color: $link-color;
      }
    }

    .namee {
      width: 25%;
      margin: auto;
    }

    .applicationCount {
      width: 4rem;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      .external {
        position: relative;
        font-weight: 500;
        font-size: 13px;
        background-color: #adcdff;
        color: white;
        padding: 2px 3px;
        margin-left: 0.5em;
      }
    }

    .pdf {
      display: flex;
      width: 10%;
      justify-content: flex-start;
      @extend .onlyNonNarrow;
    }

    .completed {
      width: 15%;
      display: flex;
      justify-content: center;
      @extend .onlyNonNarrow;
    }

    .add {
      display: flex;
      width: 22%;
      @extend .onlyNonNarrow;
    }

    .highSchoolId {
      width: 15%;
      @extend .onlyNonNarrow;
    }

    .line2 {
      margin-top: 0.3rem;
      font-size: 13px;
    }
  }
}
