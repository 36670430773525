@import '../variables.scss';

.Users {
  > .body {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    > .content {
      justify-content: left;
      padding: 0.3rem 1rem;
      padding-bottom: 1rem;
      max-width: 100%;
    }
  }

  .download-container {
    text-align: right;
    font-size: 14px;
    margin-bottom: 1rem;

    text-decoration: none;

    color: $link-color;

    &:hover {
      text-decoration: underline;
    }
  }

  .TypeSelector {
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
    flex-wrap: wrap;

    > * {
      margin-bottom: 1rem;
      margin-left: 1em;

      border: 1px solid lighten($link-color, 40%);
      border-radius: 5px;
      padding: 0.2em 0.3em;
      background-color: lighten($link-color, 54%);

      text-decoration: none;

      color: lighten($link-color, 10%);

      &:hover {
        color: darken($link-color, 10%);
        border: 1px solid $link-color;
      }
    }

    .current {
      font-weight: 500;

      $color: #f46d42;
      background-color: $color;
      color: white;
      border: 1px solid $color;
    }
  }

  .AdvancedUserList {
    max-width: 800px;
  }
}
