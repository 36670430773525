.RangeInput {
  display: flex;
  align-items: center;

  input {
    margin-left: 0.4em;
    margin-right: 0.4em;

    width: 5em;
    text-align: right;
  }

  input:first-child {
    margin-left: 0;
  }

  .text {
    margin-bottom: 2px;
  }
}
