@import '../variables.scss';

.scholarships-to-check {
  > .body {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    > .content {
      justify-content: left;
      padding: 0.3rem 1rem;
      padding-bottom: 1rem;

      .loadingIcon {
        display: block;
        margin: 0 auto;
        width: 150px;
      }
    }
  }
}
