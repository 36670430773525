@import '../variables.scss';

.Warning {
  fill: $warning-color;
  display: block;

  width: $bubble-width;
  height: $bubble-width;
  margin-right: $bubble-margin;

  position: relative;
  top: -1px;
}
