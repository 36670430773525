@import '../../variables.scss';

$animation-time: 100ms;

.FormFieldMultipleSelect {
  $max-lines-visible: 12;

  input {
    background: none;
  }

  // used on desktop as a click target to close
  .background {
    display: none;

    @media (min-width: $narrow-breakpoint) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  // used on mobile as semi-transparent background and a click target to close
  .popup-container {
    // mobile-specific
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color $animation-time ease-in-out;
    z-index: $PageNavigationMobile-z-index + 1;

    @media (min-width: $narrow-breakpoint) {
      display: block;
      position: initial;
      background: none;
      z-index: auto;
    }

    &.closed {
      background-color: rgba(#a0a0a0, 0);
      .popup {
        opacity: 0;
        max-height: 0;
      }
    }

    &.open {
      background-color: rgba(#a0a0a0, 0.7);

      .popup {
        opacity: 1;
        max-height: $max-lines-visible * 2.2em;
      }
    }

    .popup {
      // mobile-specific
      width: 350px;
      transition: opacity $animation-time ease-in-out;

      @media (min-width: $narrow-breakpoint) {
        position: absolute;
        width: 100%;
        z-index: 1;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 1px 4px rgba(0, 0, 0, 0.15);
        transition: opacity $animation-time ease-in-out,
          max-height $animation-time ease-in-out;
      }

      background-color: lighten($light-background-color, 7%);
      overflow: scroll;

      .option {
        padding: 0.5em 0.6em;
        display: flex;
        align-items: center;

        svg {
          width: 1.2em;
          height: 1.2em;
          margin-right: 0.6em;

          fill: lighten($light-text-color, 10%);
        }

        &.selected svg {
          fill: darken($input-line-color, 20%);
        }

        .label {
          flex: 1;
          text-align: left;
        }

        &:hover {
          background-color: $light-background-color;
        }
      }
    }
  }
}
