@import '../variables.scss';

$gutter: 1rem;
$bubble-top: 0.55em;

.only-screenreaders {
  height: 1px;
  position: absolute;
  overflow: hidden;
  width: 1px;
}

.faqquestionanswer {
  .react-slidedown {
    transition-duration: 0.05s;
  }
  .faqQAfields{
    margin-left: 6px;
    position: relative;


  }
  .faq-question{
    width: 38%;
}
.faq-answer{
    width: 50%;
}
  .error {
    color: $warning-color;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }

  .list-faqQA {
    position: relative;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 0.3rem;
    padding-right: $gutter * 1.5;

    .NumberBubble {
      margin-right: $gutter;
      background-color: lighten($light-text-color, 30%);
      margin-top: $bubble-top;
    }

    .fields {
      margin-top: 2px;
      flex-grow: 1;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: auto;
      padding-bottom: 1.2rem;
    }

    &:hover button.delete {
      display: block;
    }
  }

  .button.add,
  .button.delete {
    .icon {
      svg {
        fill: lighten($link-color, 15%);
        width: $bubble-width;
        height: $bubble-width;
      }
    }

    &:focus {
      outline: none;

      .label {
        color: darken($link-color, 10%);
      }
    }

    &:hover,
    &:focus {
      .icon svg {
        fill: darken($link-color, 10%);
      }
    }
  }

  .button.delete {
    @media (hover: hover) {
      display: none;
    }

    margin-top: $bubble-top;
    position: absolute;
    right: 0;

    .label {
      @extend .only-screenreaders;
    }
  }

  .button.add {
    display: flex;

    .icon {
      margin-right: $gutter;
    }

    .label {
      font-size: 14px;
      color: $link-color;
      padding-top: 1px;
    }

    &:hover {
      .label {
        color: darken($link-color, 10%);
        text-decoration: underline;
      }
    }
	}
	    .buttons {
      margin-top: 1.25rem;

      display: flex;

      .retry,
      .cancel {
        svg {
          width: 1.2rem;
          height: 1.2rem;
          fill: $warning-color;
          stroke: $warning-color;
        }

        &:first-child {
          margin-right: 1rem;
        }

        &:hover {
          svg {
            fill: darken($warning-color, 20%);
            stroke: darken($warning-color, 20%);
          }
        }
      }
    }
}
