@import '../variables.scss';

.content {
  padding: 0 0.6rem;

  @media (min-width: $narrow-breakpoint) {
    padding: 0 1.8rem;
  }

  padding-bottom: 1rem;

  .warn {
    font-size: 13px;
    font-weight: 500;
    color: $light-text-color;
    margin-bottom: 1em;
  }

  .data {
    font-size: 14px;
    white-space: pre;
    max-width: 100%;
    overflow: scroll;
  }

  input,
  textarea {
    width: 100%;
  }
}
