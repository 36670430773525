// Borders

$euiBorderWidthThin: 1px;
$euiBorderWidthThick: 2px;

$euiBorderColor: $euiColorLightShade;
$euiBorderRadius: $euiSizeS * .75;
$euiBorderRadiusSmall: $euiSizeS * .5;
$euiBorderThick: $euiBorderWidthThick solid $euiBorderColor;
$euiBorderThin: $euiBorderWidthThin solid $euiBorderColor;
$euiBorderEditable: $euiBorderWidthThick dotted $euiBorderColor;
