@import '../variables.scss';

.PageNavigationDesktop {
  $page-padding: 16px;
  $font-size: 14px;

  .page {
    cursor: pointer;
    padding: 1rem;
    font-size: $font-size;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;

    &:hover {
      background-color: $hover-background-color;
    }

    &.warn {
      color: $warning-color;
    }

    &.active {
      cursor: auto;
      color: darken($link-color, 5%);
      background-color: $light-background-color;

      &:hover {
        background-color: darken($light-background-color, 3%);
      }

      .NumberBubble {
        background-color: $link-color;
      }

      &.warn {
        color: saturate(darken($warning-color, 20%), 10%);
      }
    }

    .name {
      flex-grow: 1;
    }
  }
}
