.imageRenderer {
  overflow: hidden;
  position: relative;

  .rendererContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageWrapper {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;

      & > div {
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
