// TODO: Find a better way to include variables file, instead of having it imported for every module, split it to submodules. Consider using 'sass-resources-loader'
$narrow-breakpoint: 600px;
$wide-breakpoint: 1000px;
$intermediate-breakpoint: ($wide-breakpoint + $narrow-breakpoint) /2;

$narrow-page-margin: 0.6rem;
$page-margin: 19px;
$wide-page-margin: 38px;

$PageNavigationMobile-height: 37px;
$PageNavigationMobile-z-index: 10;
$SubmitBanner-height: 69px;

$bubble-width: 18px;
$bubble-margin: 12px;

$light-text-color: #505050;
$lighter-text-color: lighten($light-text-color, 20%);
$dark-text-color: #303030;
$link-color: #3269a5;
$link-hover-color: darken(saturate($link-color, 10%), 10%);
$title-contrast-color: #e47446;
$blue-color: #5295ff;

$input-vertical-padding: 0.3rem;
$input-line-color: #7eadf3;

$hover-background-color: #fafaff;
$light-background-color: #e0ecff;
$warning-color: #c14f20;
$orange-color: #ff8e47;
$ok-color: #1d7325;
$disabled-color: rgb(151, 151, 151);

$title-font: 'Montserrat';

$logo-padding-left: 20px;
$logo-narrow-width: 150px;
$logo-wide-width: 200px;

$submission-narrow-drawer-width: 240px;
$submission-intermediate-drawer-width: $submission-narrow-drawer-width+2 * $wide-page-margin;


