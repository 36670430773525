.Questions {
  > .body {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    > .content {
      justify-content: left;
      padding: 0.3rem 1rem;
      padding-bottom: 1rem;
    }
  }

  .Answer {
    .help {
      font-weight: 500;
      font-size: 13px;
    }
    .question {
      margin-top: 1em;
      border-left: 4px solid #ccc;
      padding-left: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      color: #888;
    }

    textarea {
      margin: 1.4em 0;
    }

    .button {
      margin-right: 1em;
    }
  }
}
