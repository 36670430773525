@import '../../variables.scss';

$gutter: 1rem;

.only-screenreaders {
  height: 1px;
  position: absolute;
  overflow: hidden;
  width: 1px;
}

.FormList {
  margin-bottom: 14px;

  .react-slidedown {
    transition-duration: 0.05s;
  }

  .help {
    margin-top: -0.4rem;
    margin-bottom: 1.2rem;
  }

  .error {
    color: $warning-color;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }

  .list-item {
    position: relative;
    display: flex;
    margin-bottom: 2rem;

    .NumberBubble {
      margin-right: $gutter;
      background-color: lighten($light-text-color, 30%);
    }

    .fields {
      margin-top: 2px;
      flex-grow: 1;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: auto;
      padding-bottom: 1.2rem;
    }

    &:hover .button.delete {
      display: block;
    }
  }

  .button.add,
  .button.delete {
    .icon {
      margin-right: $gutter;

      svg {
        fill: lighten($link-color, 15%);
        width: $bubble-width;
        height: $bubble-width;
      }
    }

    &:focus {
      outline: none;

      .label {
        color: darken($link-color, 10%);
      }
    }

    &:hover,
    &:focus {
      .icon svg {
        fill: darken($link-color, 10%);
      }
    }
  }

  .button.delete {
    @media (hover: hover) {
      display: none;
    }

    position: absolute;
    top: 2 * $bubble-width;

    .label {
      @extend .only-screenreaders;
    }
  }

  .button.add {
    display: flex;

    .label {
      font-size: 14px;
      color: $link-color;
      padding-top: 1px;
    }

    &:hover {
      .label {
        color: darken($link-color, 10%);
        text-decoration: underline;
      }
    }
  }
}
