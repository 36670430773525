.ApplicationsList {
  p.sorter {
    text-align: center;
    font-size: 12px;
    background-color: #f5f7fa;
    padding: 6px;
    margin: 10px 0;
    color: #000;
  }

  p span {
    cursor: pointer;
  }

  p span.selected {
    cursor: normal;
    font-weight: bold;
  }
}
