$euiPanelPaddingModifiers: (
  'paddingSmall': $euiSizeS,
  'paddingMedium': $euiSize,
  'paddingLarge': $euiSizeL
);

// not being used by panels, so we set it strictly in panel component
$euiPanelBorderRadiusModifiers: (
  'borderRadiusNone': 0,
  'borderRadiusMedium': $euiBorderRadius
);

$euiPanelBackgroundColorModifiers: (
  'transparent': transparent,
  'plain': $euiColorEmptyShade,
  'subdued': $euiPageBackgroundColor,
  'accent': tintOrShade($euiColorAccent, 90%, 70%),
  'primary': tintOrShade($euiColorPrimary, 90%, 70%),
  'success': tintOrShade($euiColorSuccess, 90%, 70%),
  'warning': tintOrShade($euiColorWarning, 90%, 70%),
  'danger': tintOrShade($euiColorDanger, 90%, 70%)
);
