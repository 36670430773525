@import '../variables.scss';

.NumberBubble {
  min-width: $bubble-width;
  width: $bubble-width;
  height: $bubble-width;
  margin-right: $bubble-margin;
  font-size: 12px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #808080;
  color: white;
  border-radius: 1000px;

  // > div {
  //   // somehow, the numbers look worse aligned without the fixed width
  //   width: 7px;
  // }
}
