// Code
$euiCodeBlockBackgroundColor: $euiColorLightestShade !default;
$euiCodeBlockColor: makeHighContrastColor($euiTextColor, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockSelectedBackgroundColor: inherit !default;
$euiCodeBlockCommentColor: makeHighContrastColor($euiTextSubduedColor, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockSelectorTagColor: inherit !default;
$euiCodeBlockStringColor: makeHighContrastColor($euiColorVis2, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockTagColor: makeHighContrastColor($euiColorVis1, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockNameColor: makeHighContrastColor($euiColorVis1, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockNumberColor: makeHighContrastColor($euiColorVis0, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockKeywordColor: makeHighContrastColor($euiColorVis3, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockFunctionTitleColor: inherit !default;
$euiCodeBlockTypeColor: makeHighContrastColor($euiColorVis1, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockAttributeColor: inherit !default;
$euiCodeBlockSymbolColor: makeHighContrastColor($euiColorVis9, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockParamsColor: inherit !default;
$euiCodeBlockMetaColor:  makeHighContrastColor($euiTextSubduedColor, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockTitleColor: makeHighContrastColor($euiColorVis7, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockSectionColor: makeHighContrastColor($euiColorVis9, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockAdditionColor: makeHighContrastColor($euiColorVis0, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockDeletionColor: makeHighContrastColor($euiColorDanger, $euiCodeBlockBackgroundColor) !default;
$euiCodeBlockSelectorClassColor: inherit !default;
$euiCodeBlockSelectorIdColor: inherit !default;
